import { type FC } from "react";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import { Tooltip, Popconfirm } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { type DeleteIconProps } from "../types";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";
import deleteDiseableIcon from "../../../assets/images/delete-disabled.svg";
import deleteIcon from "../../../assets/images/delete.svg";
import "./deleteButton.less";

export const DeleteButton: FC<DeleteIconProps> = ({
  onClick,
  children,
  dataName,
  disabled,
  from,
}) => {
  const { t } = useTranslation();

  const tootTipText = formatTranslation(
    t("protocols.deleteDisableButtonTooltipText")
  );

  const getDeletedTooltipText = (): string => {
    switch (from) {
      case "objectives":
        return formatTranslation(
          t("protocolObjectives.deleteButtonTooltipText")
        );
      case "product":
        return formatTranslation(t("products.deleteButtonTooltipText"));
      default:
        return formatTranslation(t("protocols.deleteButtonTooltipText"));
    }
  };

  return (
    <Tooltip
      data-testid="tooltip-text"
      placement="left"
      title={disabled ? tootTipText : getDeletedTooltipText()}
    >
      <Popconfirm
        rootClassName="protocol-delete-popup"
        placement="topRight"
        title={`Delete the ${dataName as string}?`}
        description={
          <span className="del-label">
            {formatTranslation(t("trials.deleteText"))}
          </span>
        }
        icon={<QuestionCircleOutlined />}
        onConfirm={onClick}
      >
        <Button
          className={disabled ? "delete-button-disabled" : "delete-button"}
          disabled={disabled}
        >
          <img
            src={disabled ? deleteDiseableIcon : deleteIcon}
            alt="delete-icon-disabled"
          />
          {children}
        </Button>
      </Popconfirm>
    </Tooltip>
  );
};
