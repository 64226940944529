/* eslint-disable */
import type {
  BufferZone,
  NewTrialStateInterface,
} from "../../pages/new-trail/types";
import type {
  FieldDataInterface,
  PolygonGeoJSON,
} from "../components/MapComponent/type";
import * as turf from "@turf/turf";

const FEATURES_INDEX_ZERO = 0;
const WHITE_COLOR = "white";

export const addBufferZoneLayer = (
  map: mapboxgl.Map | null,
  fieldData: FieldDataInterface[],
  newTrialState: NewTrialStateInterface,
  polygonGeoJSON: PolygonGeoJSON
): void => {
  if (!map || !fieldData || !fieldData?.length) return;

  const bufferZoneArray = newTrialState.buffer_zone;

  if (bufferZoneArray.length === 0) {
    for (let i = 0; map.getSource(`dash-line-geojson-data-${i}`); i++) {
      map.removeLayer(`dash-line-layer-${i}`);
      map.removeSource(`dash-line-geojson-data-${i}`);
    }
  }

  bufferZoneArray.forEach((bufferItem: BufferZone, index: number) => {
    const bufferSizeInMeters = -bufferItem.size;
    const visible = bufferItem.visible;
    const buffer = turf.buffer(
      polygonGeoJSON.features[FEATURES_INDEX_ZERO].geometry,
      bufferSizeInMeters,
      {
        units: "meters",
      }
    );
    const bufferBoundary = turf.polygonToLine(buffer);

    const sourceId = `dash-line-geojson-data-${index}`;
    const layerId = `dash-line-layer-${index}`;

    if (!map.getSource(sourceId)) {
      map.addSource(sourceId, {
        type: "geojson",
        data: bufferBoundary,
      });

      map.addLayer({
        id: layerId,
        type: "line",
        source: sourceId,
        paint: {
          "line-color": WHITE_COLOR,
          "line-width": 2,
          "line-dasharray": [1, 1],
        },
      });
    } else {
      (map.getSource(sourceId) as mapboxgl.GeoJSONSource).setData(
        bufferBoundary
      );

      if (map.getLayer(layerId)) {
        map.setLayoutProperty(
          layerId,
          "visibility",
          visible ? "visible" : "none"
        );
      }
    }
  });
};
