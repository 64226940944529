import { type ReactElement } from "react";
import { Card, Flex, Divider, Space } from "antd";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { EditButton } from "../../../../shared/layout/EditButton";
import { route } from "../../../constant";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../../shared/utils/translationUtils";
import { type BiologicalProductDetails } from "../../../new-trail/types";

interface BiologicalSummaryInterface {
  biologicals: BiologicalProductDetails | null;
}

const BiologicalProductSummaryCard = ({
  biologicals,
}: BiologicalSummaryInterface): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const protocolId: string | null = searchParams.get("protocol_id");
  const product = biologicals?.products?.[0]
    ? biologicals?.products?.[0]
    : null;
  const measureUnit =
    product?.measure_unit === "KILOGRAMS" ? "kg/ha" : "bla/ha";
  return (
    <Card
      title={
        <div className="text-xl semibold">
          {formatTranslation(t("createProtocol.summary.biological"))}
        </div>
      }
      extra={
        <EditButton
          from="biologicals"
          onClick={() => {
            navigate({
              pathname: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_PRODUCTS.path}`,
              search: createSearchParams({
                protocol_id: protocolId ?? "",
              }).toString(),
            });
          }}
        />
      }
      className="summary-card"
    >
      <Flex gap={16} align="start" justify="start">
        <div>
          <p className="summary-card-static-text">
            {formatTranslation(t("createProtocol.summary.biological.product"))}
          </p>
          <p className="summary-card-dynamic-text">{product?.variety}</p>
        </div>
        <Divider type="vertical" style={{ height: "52px" }} />
        <div>
          <p className="summary-card-static-text">
            {formatTranslation(
              t("createProtocol.summary.biological.productRate")
            )}
          </p>
          <p className="summary-card-dynamic-text">
            {product?.rate} {measureUnit}
          </p>
        </div>
        <Divider type="vertical" style={{ height: "52px" }} />
        <div>
          <p className="summary-card-static-text">
            {formatTranslation(
              t("createProtocol.summary.biological.tankApplicationRange")
            )}
          </p>
          <p className="summary-card-dynamic-text">
            {biologicals?.tank_mix_rate?.min} {measureUnit} -{" "}
            {biologicals?.tank_mix_rate?.max} {measureUnit}
          </p>
        </div>
      </Flex>
      <Space direction="vertical" size={8}>
        {biologicals?.applications?.map((item: any, index: number) => (
          <div key={index}>
            <p className="summary-card-static-text">
              {formatTranslation(
                t("createProtocol.summary.biological.application")
              )}{" "}
              {index + 1}
            </p>
            <p className="summary-card-dynamic-text">{item?.description}</p>
          </div>
        ))}
      </Space>
    </Card>
  );
};
export default BiologicalProductSummaryCard;
