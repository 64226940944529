import React from "react";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  MailOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import { type TrialDataInterface } from "./types";
import { Dropdown, type MenuProps, Popconfirm, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { route } from "../constant";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../shared/utils/translationUtils";
import useAxios from "../../shared/custom-hooks/useAxios";
import { useAppState } from "../../lib/appContext/AppContext";
import { formatDate } from "../create-protocol/utils";

export const TrialListColumns = (
  handleDeleteTrialData: any,
  openModal: any,
  openDrawer: any
): any => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { stateValue } = useAppState();
  const farmId = stateValue?.farmValue?.id ?? "";
  const handleNavigation = (id: string): void => {
    navigate(`/app/${route.TRIALS.path}/${id}`);
  };

  const { response: farmProperties } = useAxios(
    `/properties/${farmId}/fields?attributes=geometry`,
    true
  );

  const fieldData = Array.isArray(farmProperties?.content)
    ? farmProperties?.content
    : [];

  const getFieldName = (fieldId: string): string => {
    return fieldData?.length > 0
      ? fieldData?.find((item: any) => item?.id?.includes(fieldId))?.name
      : "";
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Edit",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      label: "Archive",
      icon: <MailOutlined />,
      disabled: true,
    },
    {
      key: "3",
      label: "Delete",
      icon: <DeleteOutlined />,
    },
  ];

  const TRIAL_COLUMNS = [
    {
      title: formatTranslation(t("trials.tableTitle1")),
      dataIndex: "name",
      key: "name",
      sorter: (a: TrialDataInterface, b: TrialDataInterface) =>
        a.name.localeCompare(b.name),
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: formatTranslation(t("trials.tableTitle4")),
      dataIndex: "field_name",
      sorter: (a: TrialDataInterface, b: TrialDataInterface) =>
        a?.field_name?.localeCompare(b?.field_name),
      render: (_a: any, record: any) => {
        return (
          <span>
            {record?.field_ids.length && getFieldName(record?.field_ids[0])}
          </span>
        );
      },
      key: "field_name",
    },
    {
      title: formatTranslation(t("createProtocol.basicInfo.protocolCrop")),
      dataIndex: "crop",
      sorter: (a: TrialDataInterface, b: TrialDataInterface) =>
        a.crop.localeCompare(b.crop),
      render: (text: string) => <span>{text}</span>,
      key: "crop",
    },
    {
      title: formatTranslation(t("trials.tableTitle5")),
      dataIndex: "start_date",
      sorter: (a: TrialDataInterface, b: TrialDataInterface) =>
        new Date(b.start_date).getTime() - new Date(a.start_date).getTime(),
      render: (text: string) => <span>{formatDate(text)}</span>,
      key: "start_date",
    },
    {
      title: formatTranslation(t("trials.tableTitle6")),
      dataIndex: "end_date",
      sorter: (a: TrialDataInterface, b: TrialDataInterface) =>
        new Date(b.end_date).getTime() - new Date(a.end_date).getTime(),
      key: "end_date",
      render: (text: string) => <span>{formatDate(text)}</span>,
    },
    {
      title: formatTranslation(t("trials.tableTitle2")),
      dataIndex: "protocol_name",
      key: "protocol_name",
      sorter: (a: TrialDataInterface, b: TrialDataInterface) =>
        a?.protocol_name.localeCompare(b?.protocol_name),
      render: (_: any, record: any) => (
        <span
          className="protocol-name"
          data-id={record?.id}
          onClick={() => record?.id && openDrawer(record?.protocol_id)}
        >
          {record?.protocol_name}
        </span>
      ),
    },
    {
      title: formatTranslation(t("trials.tableTitle7")),
      key: "action",
      render: (_: any, record: any) => (
        <div className="action-btn">
          {/* <Dropdown menu={{ items }} >
              <Space>
                <MoreOutlined />   // TODO: Add Dropdown component here
              </Space>
          </Dropdown> */}

          <Button
            className={record?.is_exportable ? "" : "export-workorder-icon"}
            disabled={record?.is_exportable === false}
            onClick={(): void => {
              openModal(record?.id, record?.field_ids[0]);
            }}
          >
            <DownloadOutlined />
          </Button>
          <Button
            onClick={() => {
              handleNavigation(record?.id);
            }}
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            rootClassName="trial-delete-popup"
            title={`Delete the ${record.name as string}?`}
            description={
              <span className="del-label">
                {formatTranslation(t("trials.deleteText"))}
              </span>
            }
            icon={<QuestionCircleOutlined />}
            onConfirm={() => handleDeleteTrialData(record?.id)}
            onCancel={() => {}}
          >
            <Button className="del-btn">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return TRIAL_COLUMNS;
};
