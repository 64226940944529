import { useState, useEffect, type ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Form, Tooltip, Row, Col } from "antd";
import { formatTranslation } from "../../../../../../shared/utils/translationUtils";
import infoIcon from "../../../../../../assets/images/info.svg";
import { InputNumber } from "syngenta-digital-cropwise-react-ui-kit";
import { useCreateProtocolForm } from "../../../..";

interface MixRateInterface {
  loading?: boolean;
}

const MixRateApplicationVolume = ({
  loading = false,
}: MixRateInterface): ReactElement => {
  const { t } = useTranslation();
  const { form, setFormValidationData, formValidationData } =
    useCreateProtocolForm();
  const [data, setData] = useState({
    min: 0,
    max: 0,
  });

  useEffect(() => {
    if (!loading) {
      setInitialData();
    }
  }, [loading]);
  function setInitialData(): void {
    const formValues = form.getFieldsValue(true);
    const tankMixRate = formValues?.biologicals?.tank_mix_rate;
    if (tankMixRate) {
      setData({ ...tankMixRate });
    }
  }

  const handleChange = (key: string, value: string): any => {
    const formValues = form.getFieldsValue(true);
    const newValue = { ...data, [key]: value };
    setData({ ...newValue });
    form.setFieldsValue({
      biologicals: {
        ...formValues?.biologicals,
        tank_mix_rate: { ...newValue },
      },
    });
    setFormValidationData({
      ...formValidationData,
      biologicalTankMixRate: newValue,
    });
  };
  return (
    <div className="input-container">
      <div className="product-config">
        <h3 className="config-header">
          {formatTranslation(
            t("createProtocol.products.biologicals.product.tankMixRate")
          )}
        </h3>
        <Tooltip
          className="d-flex"
          placement="right"
          title={formatTranslation(
            t("createProtocol.products.biologicals.product.tankMixRate.tooltip")
          )}
        >
          <img src={infoIcon} alt="info-icon" className="info-icon" />
        </Tooltip>
      </div>
      <Row gutter={16}>
        <Col span={12}>
          <Form className="min-max-rates-inputs">
            <Form.Item
              label={formatTranslation(
                t("createProtocol.products.biologicals.product.minLabel")
              )}
              className="input-rate-label"
              required
              rules={[
                {
                  required: true,
                  message: formatTranslation(
                    t(
                      "createProtocol.products.biologicals.product.minErrorLabel"
                    )
                  ),
                },
                {
                  type: "number",
                  min: 0,
                  message: formatTranslation(
                    t(
                      "createProtocol.products.biologicals.product.minErrorLabel"
                    )
                  ),
                },
              ]}
            >
              <InputNumber
                type="number"
                min={0}
                disabled={loading}
                value={data.min}
                onChange={(minValue: any): void => {
                  handleChange("min", minValue);
                }}
                width={"100%"}
                className="input-number"
                size="large"
              />
            </Form.Item>
          </Form>
        </Col>
        <Col span={12}>
          <Form className="min-max-rates-inputs">
            <Form.Item
              label={formatTranslation(
                t("createProtocol.products.biologicals.product.maxLabel")
              )}
              required
              className="input-rate-label"
              rules={[
                {
                  required: true,
                  message: formatTranslation(
                    t(
                      "createProtocol.products.biologicals.product.maxErrorLabel"
                    )
                  ),
                },
                {
                  type: "number",
                  min: 0,
                  message: formatTranslation(
                    t(
                      "createProtocol.products.biologicals.product.maxErrorLabel"
                    )
                  ),
                },
              ]}
            >
              <InputNumber
                type="number"
                min={1}
                value={data.max}
                disabled={loading}
                onChange={(maxValue: any): void => {
                  handleChange("max", maxValue);
                }}
                className="input-number"
                size="large"
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default MixRateApplicationVolume;
