import { useState } from "react";
import { Input, Dropdown, Menu, Checkbox, Button as AntButton } from "antd";
import { PrimaryButton } from "../../shared/layout/PrimaryButton";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import filterIcon from "../../assets/images/icon-filter.svg";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../shared/utils/translationUtils";
import { Search } from "syngenta-digital-cropwise-react-ui-kit";

interface ProtocolsHeaderProps {
  title: string;
  showCreateButton: any;
  onSearch: any;
  onFilter: any;
  onCreate: any;
  searchValue: any;
}

export const ProtocolsHeader = ({
  showCreateButton,
  onSearch,
  onFilter,
  onCreate,
  searchValue,
}: ProtocolsHeaderProps): JSX.Element => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { t } = useTranslation();
  const [pendingSelectedStatuses, setPendingSelectedStatuses] = useState<
    string[]
  >([]);

  // Handle checkbox selection changes
  const handleFilterChange = (status: string): void => {
    setPendingSelectedStatuses((prev) =>
      prev.includes(status)
        ? prev.filter((s) => s !== status)
        : [...prev, status]
    );
  };

  // Apply the selected filters
  const applyFilter = (): void => {
    onFilter(pendingSelectedStatuses);
    setDropdownVisible(false);
  };

  // Reset the selected filters
  const resetFilter = (): void => {
    setPendingSelectedStatuses([]);
    onFilter([]);
    setDropdownVisible(false);
  };

  // Menu content for filters
  const menu = (
    <Menu>
      <Menu.Item>
        <Checkbox
          checked={pendingSelectedStatuses.includes("draft")}
          onChange={() => {
            handleFilterChange("draft");
          }}
        >
          Draft
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="published">
        <Checkbox
          checked={pendingSelectedStatuses.includes("published")}
          onChange={() => {
            handleFilterChange("published");
          }}
        >
          Published
        </Checkbox>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="actions">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <AntButton
            type="link"
            onClick={() => {
              resetFilter();
            }}
          >
            Reset
          </AntButton>
          <AntButton
            type="primary"
            onClick={() => {
              applyFilter();
            }}
          >
            Apply
          </AntButton>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {/* Title and Create Protocol Button */}
      <div className="protocols-header">
        <h3 className="text-xl text-semibold">
          {formatTranslation(t("protocols.title"))}
        </h3>
        <PrimaryButton onClick={onCreate}>
          <PlusOutlined />
          {formatTranslation(t("protocols.createButton"))}
        </PrimaryButton>
      </div>
      {/* Search and Filter Actions */}
      <div className="flex-content-protocol">
        <p className="text-lg">
          {formatTranslation(t("protocols.protocolList"))}
        </p>
        <div className="header-actions">
          {/* Search Input */}
          <Input
            placeholder="Search"
            allowClear // Enables the clear (cross) icon
            value={searchValue}
            onChange={onSearch} // Trigger search dynamically
            style={{ width: 200, marginRight: "16px" }}
            prefix={<SearchOutlined style={{ color: "#696f88" }} />}
          />
          {/* Filter Dropdown */}
          <Dropdown
            className="protocols-filter-btn"
            overlay={menu}
            trigger={["click"]}
            visible={dropdownVisible}
            onVisibleChange={(flag) => {
              setDropdownVisible(flag);
            }}
          >
            <AntButton icon={<img src={filterIcon} alt="Filter" />} />
          </Dropdown>
        </div>
      </div>
    </>
  );
};
