import { route } from "../constant";
import { type objectivesTargetType, type ProtocolStepItemType } from "./types";
import { type TabsProps } from "antd";
import ProductsSeedComponent from "./step-components/Products/ProductsSeedComponent";
import ProductsNutrientsComponent from "./step-components/Products/ProductsNutrientsComponent";
import ProductsBiologicalComponents from "./step-components/Products/Biological/ProductsBiologicalComponents";

export const protocolsSteps: ProtocolStepItemType[] = [
  {
    title: "Trial Type",
    path: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_TRIALTYPE.path}`,
    stepNumber: 1,
  },
  {
    title: "Protocol Rules",
    path: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_RULES.path}`,
    stepNumber: 2,
  },

  {
    title: "Products",
    path: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_PRODUCTS.path}`,
    stepNumber: 3,
  },
  {
    title: "Summary",
    path: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_SUMMARY.path}`,
    stepNumber: 4,
  },
];

export const SUBMIT_PROTOCOL_LABEL = "Save and configure trial";
export const targets: objectivesTargetType[] = [
  {
    label: "Yield",
    value: "Yield",
  },
  {
    label: "Moisture",
    value: "Moisture",
  },
  {
    label: "Peak NDVI",
    value: "Peak NDVI",
  },
  {
    label: "Dry Matter",
    value: "Dry Matter",
  },
  // {
  //   label: "Seed Rate",
  //   value: "seed_rate",
  // },
  // {
  //   label: "Sunflower Rate",
  //   value: "sunflower_rate",
  // },
  // {
  //   label: "Silage Rate",
  //   value: "silage rate",
  // },
  // {
  //   label: "Cereals Rate",
  //   value: "cereals rate",
  // },
  // {
  //   label: "Fert Rate",
  //   value: "fert rate",
  // },
];

export const dummyObjectives = [
  {
    name: "First Objective",
    target: "Crop Establishment",
    description: "This is a test description for the first objective",
  },
  {
    name: " Objective 2",
    target: "Crop Establishment",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
  },
];

export const measureUnitOptions = [
  {
    value: "seeds/m2",
    label: (
      <>
        seeds/m<sup>2</sup>
      </>
    ),
  },
  {
    value: "seeds/ha",
    label: <>seeds/ha</>,
  },
];

export const nutrientTypeOptions: any = [
  {
    label: <span className="nutrient-type-category">Basic Nutrients</span>,
    title: "Basic Nutrients",
    options: [
      { label: <span>N | Nitrogen</span>, value: "N", key: "nitrogen" },
      {
        label: <span>P | Phosphorus (P2O5 | Phosphorus Pentoxide)</span>,
        value: "P",
        key: "phosphorusPentoxide",
      },
      {
        label: <span>K | Potassium (K2O | Potassium Oxide)</span>,
        value: "K",
        key: "potassiumOxide",
      },
    ],
  },
  {
    label: <span className="nutrient-type-category">Aditional Nutrients</span>,
    title: "Aditional Nutrients",
    options: [
      { label: <span>Cd | Cadmium</span>, value: "Cd", key: "cadmium" },
      { label: <span>Cr | Chromium</span>, value: "Cr", key: "chromium" },
      { label: <span>Pb | Lead</span>, value: "Pb | Lead", key: "lead" },
      { label: <span>Hg | Mercury</span>, value: "Hg", key: "mercury" },
    ],
  },
  {
    label: <span className="nutrient-type-category">Other Nutrients</span>,
    title: "Aditional Nutrients",
    options: [
      { label: <span>Al | Aluminum</span>, value: "Al", key: "aluminum" },
      { label: <span>B | Boron</span>, value: "B | Boron", key: "boron" },
      { label: <span>Ca | Calcium</span>, value: "Ca", key: "calcium" },
      { label: <span>Co | Cobalt</span>, value: "Co | Cobalt", key: "cobalt" },
      { label: <span>Cu | Copper</span>, value: "Cu | Copper", key: "copper" },
      { label: <span>Fe | Iron</span>, value: "Fe | Iron", key: "iron" },
      { label: <span>Mg | Magnesium</span>, value: "Mg", key: "magnesium" },
      { label: <span>Mn | Manganese</span>, value: "Mn", key: "manganese" },
      { label: <span>Mo | Molybdenum</span>, value: "Mo", key: "molybdenum" },
      { label: <span>Ni | Nickel</span>, value: "Ni | Nickel", key: "nickel" },
      { label: <span>Se | Selenium</span>, value: "Se", key: "selenium" },
      { label: <span>Si | Silicon</span>, value: "Si", key: "silicon" },
      { label: <span>Na | Sodium</span>, value: "Na | Sodium", key: "sodium" },
      { label: <span>S | Sulfur</span>, value: "S | Sulfur", key: "sulfur" },
      { label: <span>Zn | Zinc</span>, value: "Zn | Zinc", key: "zinc" },
    ],
  },
];

export const protocolProductTabs = (
  protocolType: string[]
): TabsProps["items"] =>
  [
    {
      key: "seed",
      label: <div className="label-style">Seeds</div>,
      children: <ProductsSeedComponent />,
    },
    {
      key: "biological",
      label: <div className="label-style">Biologicals</div>,
      children: (
        <div className="products-biologicals-outer-div">
          <ProductsBiologicalComponents />
        </div>
      ),
    },
    {
      key: "nutrient",
      label: <div className="label-style">Nutrients</div>,
      children: (
        <div className="products-nutrients-outer-div">
          <ProductsNutrientsComponent />
        </div>
      ),
    },
  ].filter((tab) => protocolType.includes(tab?.key));

export const BiologicalProductOptions: any = [
  {
    label: <span>Product One</span>,
    value: "1",
    key: "product1",
  },
  {
    label: <span>Product Two</span>,
    value: "2",
    key: "product1",
  },
  {
    label: <span>Product Three</span>,
    value: "3",
    key: "product1",
  },
  {
    label: <span>Product Four</span>,
    value: "4",
    key: "product1",
  },
  {
    label: <span>Product Five</span>,
    value: "5",
    key: "product1",
  },
];
