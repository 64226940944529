export const getUserAmplitudeData = (user: any, stateValue: any): any => {
  return {
    userId: user?.id,
    user_type: user?.job_title,
    user_location: user?.country_code,
    user_language: user?.locale,
    platform: "web",
    workspace_id: user?.default_workspace_id,
    organization_id: stateValue?.orgValue?.id,
    licensing_account: user?.default_licensing_account_id,
    organization_name: stateValue?.orgValue?.name,
    organization_country: stateValue?.orgValue?.country,
    farm_id: stateValue?.farmValue?.id,
    farm_name: stateValue?.farmValue?.name,
  };
};
