import React, { useEffect, useState } from "react";
import { Col, Drawer, Flex, Row, Tabs, type TabsProps } from "antd";
import AxiosInstance from "../../utils/axios";
import cropIcon from "../../../assets/images/crop-icon.svg";
import { ReactComponent as SeedsIcon } from "../../../assets/images/seeds.svg";
import { ReactComponent as SeedsGrayIcon } from "../../../assets/images/seeds-gray.svg";
import { ReactComponent as NutrientIcon } from "../../../assets/images/soil.svg";
import "./ProtocolDrawer.less";
import { getDefaultActiveTabs } from "../../../pages/create-protocol/utils";
import type {
  ProductDetails,
  ProtocolResponse,
} from "../../../pages/new-trail/types";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";
import { nutrientTypeOptions } from "../../../pages/create-protocol/constants";

interface DrawerPArams {
  protocolId: string;
  open: boolean;
  onClose: () => void;
}

const ProtcolDrawer: React.FC<DrawerPArams> = ({
  protocolId,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const [protocolData, setProtocolData] = useState<ProtocolResponse | null>(
    null
  );
  const [showFullDescription, setFullDescription] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(
    getDefaultActiveTabs(protocolData?.protocol_type ?? [])
  );

  const getProtocolData = async (): Promise<any> => {
    try {
      const response = await AxiosInstance.get(`/protocol/${protocolId}`);
      setProtocolData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (protocolId) {
      getProtocolData().catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [protocolId]);

  const showFullDescriptionHandler = (): void => {
    setFullDescription(!showFullDescription);
  };

  const items: TabsProps["items"] = [
    {
      key: "seed",
      label: "Seeds",
      children: (
        <ProtocolSeedsComponent seeds={protocolData?.product_details?.seeds} />
      ),
      icon: activeTab === "seed" ? <SeedsIcon /> : <SeedsGrayIcon />,
    },
    {
      key: "nutrient",
      label: "Nutrients",
      children: (
        <ProtocolNutrientsComponent
          nutrients={protocolData?.product_details?.nutrients}
        />
      ),
      icon: <NutrientIcon />,
    },
  ].filter((tab) => protocolData?.protocol_type?.includes(tab?.key));

  return (
    <div>
      {protocolData && (
        <Drawer
          title={protocolData?.name}
          placement="right"
          width={500}
          open={open}
          onClose={onClose}
        >
          <div className="protocol-drawer-container">
            <div className="protocol-basic-info">
              <div className="basic-info-content">
                <div className="info-label">Country</div>
                <div className="info-value">
                  {protocolData?.crop_details?.countries[0]}
                </div>
              </div>
              <div className="basic-info-content">
                <div className="info-label">
                  {formatTranslation(
                    t("createProtocol.basicInfo.protocolCrop")
                  )}
                </div>
                <div className="info-value">{protocolData?.crop}</div>
              </div>
              <div className="basic-info-content">
                <div className="info-label">Min Plot size</div>
                <div className="info-value">
                  <img src={cropIcon} alt="crop-img" />
                  <span>
                    {protocolData?.plot_minimum_size?.width} {"x"}{" "}
                    {protocolData?.plot_minimum_size?.length}m
                  </span>
                </div>
              </div>
              <div className="basic-info-content">
                <div className="info-label">Recomended Plot size</div>
                <div className="info-value">
                  {protocolData?.plot_recommended_size?.width} {"x"}{" "}
                  {protocolData?.plot_recommended_size?.length}m
                </div>
              </div>
            </div>
            <div className="basicinfo-des info-content">
              <h4>
                {formatTranslation(
                  t("createProtocol.objectives.objectiveItemDescription")
                )}
              </h4>
              <Flex justify="space-between" gap={8} align="flex-start">
                {protocolData?.description ? (
                  <>
                    <p>
                      {showFullDescription
                        ? protocolData?.description
                        : protocolData?.description.slice(0, 100)}
                    </p>
                    <button
                      className="show-less-button"
                      onClick={showFullDescriptionHandler}
                      disabled={protocolData?.description.length < 100}
                    >
                      Show {showFullDescription ? "Less" : "More"}
                    </button>
                  </>
                ) : (
                  <p>Description not Available</p>
                )}
              </Flex>
            </div>
            <div className="protocolo-objectives">
              <h3>{formatTranslation(t("createProtocol.objectives.title"))}</h3>
              {protocolData?.objectives?.length > 0 &&
                protocolData?.objectives.map(
                  (objective: any, index: number) => (
                    <div className="objective-content" key={index}>
                      <div className="info-content obj-target-info">
                        <h4>
                          {formatTranslation(
                            t("createProtocol.objectives.objectiveItemTarget")
                          )}
                        </h4>
                        <p>{objective?.name}</p>
                      </div>
                      <div className="obj-description-info info-content">
                        <h4>
                          {formatTranslation(
                            t(
                              "createProtocol.objectives.objectiveItemDescription"
                            )
                          )}
                        </h4>
                        <p>{objective?.description}</p>
                      </div>
                    </div>
                  )
                )}
            </div>
            <div className="protocol-product-info">
              <Tabs
                defaultActiveKey={getDefaultActiveTabs(
                  protocolData?.protocol_type ?? []
                )}
                items={items}
                onChange={(key) => {
                  setActiveTab(key);
                }}
              />
            </div>
          </div>
        </Drawer>
      )}
    </div>
  );
};

const ProtocolSeedsComponent: React.FC<ProductDetails> = ({ seeds }) => {
  const { t } = useTranslation();
  return (
    <div className="product-seeds">
      <h3>{formatTranslation(t("createProtocol.products.rightTilte"))}</h3>
      <div className="seeds-info">
        <Row className="seed-table-header">
          <Col span={12}>
            <h4>
              {formatTranslation(t("createProtocol.products.rightTilte"))}
            </h4>
          </Col>
          <Col span={12}>
            <h4>
              {formatTranslation(t("createProtocol.products.densityRange"))}
            </h4>
          </Col>
        </Row>
        {seeds ? (
          seeds?.products.map((product: any, index: number) => (
            <Row key={index} className="products-row">
              <Col span={12}>
                <p className="summary-card-dynamic-text">{product.variety}</p>
              </Col>
              <Col span={12}>
                {seeds?.global_density_range ? (
                  <p className="summary-card-dynamic-text">
                    {seeds?.density_range.min} - {seeds?.density_range.max}{" "}
                    {seeds?.measure_unit}
                  </p>
                ) : (
                  <p className="summary-card-dynamic-text">
                    {product.density_range.min} - {product.density_range.max}{" "}
                    {seeds?.measure_unit}
                  </p>
                )}
              </Col>
            </Row>
          ))
        ) : (
          <Row className="text-center">
            <Col>
              <p>No Data Found</p>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

const ProtocolNutrientsComponent: React.FC<ProductDetails> = ({
  nutrients,
}) => {
  const { t } = useTranslation();
  const findNutrientLabel = (value: string): string => {
    for (const category of nutrientTypeOptions) {
      const nutrient = category.options.find(
        (option: { value: string; label: string }) => option.value === value
      );
      if (nutrient) {
        return nutrient.label;
      }
    }
    return "";
  };

  return (
    <div className="product-seeds">
      <h3>{formatTranslation(t("trialtypes.label.fertiliser"))}</h3>
      {nutrients ? (
        <>
          <div className="info-content">
            <h4>Basic nutrient</h4>
            <p>{findNutrientLabel(nutrients?.products[0]?.nutrient_code)}</p>
          </div>

          <div className="protocol-basic-info">
            <div className="basic-info-content">
              <div className="info-label">
                {formatTranslation(
                  t("createProtocol.summary.nutrients.applicationRange")
                )}
              </div>
              <div className="info-value">
                {nutrients?.application_range?.min} x{" "}
                {nutrients?.application_range?.max}m
              </div>
            </div>
            <div className="basic-info-content">
              <div className="info-label">Min. Distance Unit</div>
              <div className="info-value">{nutrients?.interval_distance}%</div>
            </div>
            <div className="basic-info-content">
              <div className="info-label">No. of intervals</div>
              <div className="info-value">{nutrients?.number_of_intervals}</div>
            </div>
          </div>
        </>
      ) : (
        <p>No Data Found</p>
      )}
    </div>
  );
};

export default ProtcolDrawer;
