import { Button, Tabs } from "antd";
import { useEffect, useState, type FC } from "react";
import { getRatesAndDosageTabs } from "./constant";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";
import { RateUnitDropDown } from "./seeds";
import ProtocolDrawer from "../../../shared/layout/ProtocolDrawer";
import { useNewTrialState } from "../hooks/NewTrialStateProvider";
import { InfoCircleOutlined } from "@ant-design/icons";

const RatesDosageComponent: FC = () => {
  const { t } = useTranslation();
  const [showDrawer, setShowDrawer] = useState(false);
  const {
    trialProtocolData,
    setIsFertilizerTabActive,
    setActiveRatesTab,
    setRatesDosage,
    ratesDosage,
    trialInfo,
  } = useNewTrialState();

  const trialUOM =
    trialInfo?.trial_plots?.[0]?.properties?.seeds?.measure_unit ?? null;
  useEffect(() => {
    setRatesDosage({
      ...ratesDosage,
      unit_of_rate: trialUOM ?? ratesDosage.unit_of_rate ?? "seeds/ha",
    });
  }, [trialUOM]);

  const hideDrawer = (): void => {
    setShowDrawer(false);
  };

  const openDrawer = (): void => {
    setShowDrawer(true);
  };
  useEffect(() => {
    setIsFertilizerTabActive(
      trialProtocolData?.protocol_type?.[0] === "nutrient"
    );
    setActiveRatesTab(trialProtocolData?.protocol_type?.[0] as string);

    return () => {
      setIsFertilizerTabActive(false);
      setActiveRatesTab(trialProtocolData?.protocol_type?.[0] as string);
    };
  }, [trialProtocolData]);

  return (
    <div className="rates-dosage-main">
      <div className="title-sec">
        <h3 className="main-title">
          {formatTranslation(t("newTrial.ratesDosage.title"))}
        </h3>
      </div>
      <ProtocolDrawer
        open={showDrawer}
        onClose={hideDrawer}
        protocolId={trialProtocolData?.id ?? ""}
      />
      <Tabs
        tabBarExtraContent={
          <Button
            type="text"
            onClick={openDrawer}
            icon={<InfoCircleOutlined />}
          >
            Protocol info
          </Button>
        }
        onChange={(key) => {
          setIsFertilizerTabActive(key === "nutrient");
          setActiveRatesTab(key);
        }}
        defaultActiveKey={"0"}
        items={getRatesAndDosageTabs(trialProtocolData)}
      />
    </div>
  );
};

export default RatesDosageComponent;
