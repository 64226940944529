import { formatTranslation } from "../../utils/translationUtils";
import "./editButton.less";
import editIcon from "../../../assets/images/edit-hover.svg";
import editDiseableIcon from "../../../assets/images/edit.svg";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, Button } from "antd";
import { type EditIconProps } from "../types";

export const EditButton: FC<EditIconProps> = ({
  from,
  onClick,
  disabled,
  children,
}) => {
  const { t } = useTranslation();

  const getTooltipText = (): string => {
    switch (from) {
      case "trialInfo":
        return formatTranslation(t("trialsInfo.editButtonTooltipText"));
      case "plotDesign":
        return formatTranslation(t("trialPlotDesign.editButtonTooltipText"));
      case "ratesAndDosage":
        return formatTranslation(t("trialRate.editButtonTooltipText"));
      case "objectives":
        return formatTranslation(t("protocolObjectives.editButtonTooltipText"));
      case "seeds":
        return formatTranslation(t("seeds.editButtonTooltipText"));
      case "biologicals":
        return formatTranslation(t("biologicals.editButtonTooltipText"));
      case "nutrients":
        return formatTranslation(t("nutrients.editButtonTooltipText"));
      case "protocolType":
        return formatTranslation(t("protocolType.editButtonTooltipText"));
      case "protocolRules":
        return formatTranslation(t("protocolRules.editButtonTooltipText"));
      default:
        return formatTranslation(t("protocols.editButtonTooltipText"));
    }
  };

  const tootTipText = formatTranslation(
    t("protocols.editDisableButtonTooltipText")
  );

  return (
    <Tooltip placement="left" title={disabled ? tootTipText : getTooltipText()}>
      <Button
        className={disabled ? "edit-button-disabled" : "edit-button"}
        data-testid="edit-button"
        onClick={onClick}
        disabled={disabled}
      >
        <img src={disabled ? editDiseableIcon : editIcon} alt="edit icon" />
        {children}
      </Button>
    </Tooltip>
  );
};
