import { type FC, type ReactNode, useEffect, useMemo, useState } from "react";
import { BufferZonePopup } from "../popUp/bufferZonePopup";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import upIcon from "../../../../assets/images/keyboard-up-18-gray.svg";
import downIcon from "../../../../assets/images/keyboard-down-18-gray.svg";
import { MachineryGridPopup } from "../popUp/machineryGridPopup";
import { TrialPlotsPopup } from "../popUp/trialPopup";
import { usePlotCardState } from "../utils";
import ReplicantComponent from "../replicantComponent";
import { useNewTrialState } from "../../../../pages/new-trail/hooks/NewTrialStateProvider";
import {
  type AbLine,
  type Plots,
  type BufferZone,
  type MachineryGrid,
  type Replicants,
  type TrialPlot,
  type RenderCardsProps,
  type combineType,
} from "../../../../pages/new-trail/types";

import { Flex, InputNumber, Input, Tooltip } from "antd";
import _ from "lodash";
import { formatTranslation } from "../../../utils/translationUtils";
import { useTranslation } from "react-i18next";

export const RenderCards: FC<RenderCardsProps> = ({
  cards,
  toggleVisibility,
  data,
  setIsModalOpen,
  setDeleteData,
  trialData,
}): any => {
  const {
    handleBufferZoneData,
    handleReplicantData,
    replicantData,
    setReplicantData,
    handleMachineGridData,
    handleAbLineData,
    handleTrailPlotsData,
    handleTickedData,
    getAbLineColor,
  } = usePlotCardState();
  const { t } = useTranslation();

  const { newTrialState, setNewTrialState } = useNewTrialState();
  const [buttonDisabled, setButtonDisabled] = useState<number[]>([]);
  const [collapsedSections, setCollapsedSections] = useState<
    Record<number, boolean>
  >({});

  useEffect(() => {
    setNewTrialState((prevState: any) => {
      return {
        ...prevState,
        trial_plots: prevState.trial_plots.map(
          (plotData: TrialPlot, i: number) => {
            return {
              ...plotData,
              plot: {
                ...plotData.plot,
                isEditing: false,
              },
            };
          }
        ),
      };
    });
  }, []);

  const handleSelectButton = (data: any, i: number): void => {
    handleTickedData(data, i);
    setButtonDisabled([...buttonDisabled, i]);
  };

  const debounceWidthChange = _.debounce((e, i) => {
    handleMachineGridData(e, i);
  }, 1000);

  const isButtonDisabled = (id: number): boolean => {
    return buttonDisabled.includes(id);
  };

  const handleAbLineChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    i?: number
  ): void => {
    handleAbLineData(e.target.value, i);
  };

  const delButton = (val: combineType): ReactNode => {
    return (
      <Tooltip title={formatTranslation(t("trail.deleteSection"))}>
        <Button
          data-testid={`delete-button-${val.id as number}`}
          className="del-btn"
          onClick={() => {
            setIsModalOpen(true);
            setDeleteData(val);
          }}
        >
          <DeleteOutlined alt="del-icon" />
        </Button>
      </Tooltip>
    );
  };

  const filteredCards = useMemo(() => {
    return cards?.filter((val) => {
      if (val && typeof val === "object") {
        if (
          ("plot" in val && val.plot.key === data.name) ||
          ("key" in val && val.key === data.name)
        ) {
          return true;
        }
      }
      return false;
    });
  }, [cards, data.name]);

  const renderActionButton = (
    genericPlot: any,
    trialPlot: any,
    cardDetail: any,
    i: number
  ): ReactNode => {
    if (data.name === "Buffer Zone") {
      return (
        <BufferZonePopup
          onHandleData={handleBufferZoneData}
          // openPopoverKey={id ? "" : "openEditPopover"}
          openPopoverKey="openEditPopover"
          val={genericPlot.id}
        >
          <Button>
            <EditOutlined />
          </Button>
        </BufferZonePopup>
      );
    }
    // else if (data.name === "Machinery Grid") {
    //   return (
    //     <MachineryGridPopup
    //       onHandleData={handleMachineGridData}
    //       // openPopoverKey={id ? "" : "openEditPopover"}
    //       openPopoverKey="openEditPopover"
    //       val={genericPlot.id}
    //       trialData={trialData}
    //     >
    //       <Button>
    //         <EditOutlined />
    //       </Button>
    //     </MachineryGridPopup>
    //   );
    // }
    else if (data.name === "Trial Plots") {
      return (
        <>
          <TrialPlotsPopup
            openPopoverKey="openEditPopover"
            onHandleData={handleTrailPlotsData}
            val={trialPlot.plot.id}
            indexKey={i}
          >
            <Tooltip title={formatTranslation(t("trail.modifyPlot"))}>
              <Button>
                <EditOutlined />
              </Button>
            </Tooltip>
          </TrialPlotsPopup>
          <Tooltip title={formatTranslation(t("trail.copyTreatment"))}>
            <Button
              className="add-replicant"
              onClick={() => {
                handleReplicantData(cardDetail, i);
              }}
            >
              <PlusSquareOutlined />
            </Button>
          </Tooltip>
        </>
      );
    } else {
      return (
        <></>
        // <Button>
        //   <EditOutlined />
        // </Button>
      );
    }
  };

  return filteredCards?.map(
    (
      cardDetail:
        | AbLine
        | BufferZone
        | MachineryGrid
        | TrialPlot
        | Replicants
        | Plots,
      i
    ): ReactNode => {
      const trialPlot = cardDetail as TrialPlot;
      const genericPlot = cardDetail as
        | AbLine
        | BufferZone
        | MachineryGrid
        | Replicants
        | Plots;

      return (
        <>
          <div className="open-sec" key={`${data.name}-${i}`}>
            <div className="open-sec-title">
              {data.name === "Trial Plots" ? (
                trialPlot.plot.name
              ) : (
                <Flex
                  vertical={false}
                  gap={8}
                  className="open-sec-title-circle"
                >
                  {" "}
                  <div
                    className={`ab-color-circle ${
                      getAbLineColor(genericPlot.id, "className") as string
                    }`}
                  ></div>{" "}
                  {data.name === "A/B Line + Machinery Grid" ? (
                    <Input
                      value={newTrialState?.ab_line[i]?.name ?? null}
                      onChange={(e) => {
                        handleAbLineChange(e, i);
                      }}
                    />
                  ) : (
                    genericPlot.name
                  )}
                </Flex>
              )}

              {"size" in cardDetail && (
                <span>
                  {" "}
                  • {cardDetail.type === "EXTERNAL"
                    ? "External"
                    : "Internal"}{" "}
                  {cardDetail.size}
                </span>
              )}

              {"plot" in cardDetail && (
                <span>
                  {" "}
                  • {trialPlot?.plot.rows * trialPlot?.plot.columns} plot{" "}
                  {trialPlot?.plot.rows} x {trialPlot?.plot.columns}|
                  {trialPlot?.plot.column_size}m x {trialPlot?.plot.row_size}m
                </span>
              )}
            </div>
            <div className="action-sec">
              {data.name !== "Machinery Grid" && (
                <div className="eye-edit">
                  {renderActionButton(genericPlot, trialPlot, cardDetail, i)}
                  <Tooltip
                    title={formatTranslation(t("trail.toggleVisibility"))}
                  >
                    <Button
                      onClick={() => {
                        toggleVisibility(i, data);
                      }}
                    >
                      {genericPlot?.visible ?? trialPlot?.plot?.visible ? (
                        <EyeOutlined />
                      ) : (
                        <EyeInvisibleOutlined />
                      )}
                    </Button>
                  </Tooltip>
                </div>
              )}

              {
                <div className="del-right">
                  {delButton(
                    trialPlot?.plot?.key === "Trial Plots"
                      ? trialPlot.plot
                      : (genericPlot as combineType)
                  )}

                  {data.name === "A/B Line + Machinery Grid" && (
                    <>
                      {/* removed Tick Button which disableed A/B Line Marker Drag */}
                      <Button
                        className="collapse-toggle-btn"
                        style={{ color: "rgb(239, 3, 3)" }}
                        onClick={() => {
                          setCollapsedSections((prev) => ({
                            ...prev,
                            [i]: !prev[i],
                          }));
                        }}
                      >
                        {collapsedSections[i] ? (
                          <img src={upIcon} alt="up-icon" />
                        ) : (
                          <img src={downIcon} alt="down-icon" />
                        )}
                      </Button>
                    </>
                  )}
                </div>
              }
            </div>
          </div>
          {data?.name === "A/B Line + Machinery Grid" &&
          collapsedSections[i] ? (
            <div className="open-sec abgrid-sec">
              <h4 className="text-semibold">Machienry Grid</h4>
              <Flex vertical={false} align="center" gap={4}>
                <small>Width (m)</small>
                <InputNumber
                  min={10}
                  value={newTrialState?.ab_line[i]?.grid_width ?? null}
                  onChange={(e) => {
                    debounceWidthChange(e, i);
                  }}
                />
              </Flex>
            </div>
          ) : (
            <></>
          )}

          {data?.name === "Trial Plots" &&
            newTrialState?.trial_plots[i]?.replicants?.map(
              (valData: Replicants, index: number) => {
                return (
                  <ReplicantComponent
                    valData={valData}
                    key={`${data?.name}-${valData?.id as number}-${index}`}
                    index={index}
                    replicantData={replicantData}
                    toggleVisibility={toggleVisibility}
                    setReplicantData={setReplicantData}
                    delButton={delButton}
                  />
                );
              }
            )}
        </>
      );
    }
  );
};
