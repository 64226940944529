import React, { createContext, useContext, useMemo, useState } from "react";
import {
  type RatesDosageInterface,
  type NewTrialStateInterface,
  type PlotsValidationData,
  type ProtocolResponse,
} from "../types";

interface NewTrialStateContextValue {
  newTrialState: NewTrialStateInterface;
  setNewTrialState: React.Dispatch<React.SetStateAction<any>>;
  trialInfo: any;
  setTrialInfo: React.Dispatch<React.SetStateAction<any>>;
  ratesDosage: RatesDosageInterface;
  setRatesDosage: React.Dispatch<React.SetStateAction<RatesDosageInterface>>;
  activeKeys: any;
  setActiveKeys: any;
  trialProducts: any;
  setTrialProducts: React.Dispatch<React.SetStateAction<any>>;
  trialProtocolData: ProtocolResponse | null;
  setTrialProtocolData: any;
  ratesDosageValidation: boolean;
  setRatesDosageValidation: React.Dispatch<React.SetStateAction<any>>;
  isStandardDataValid: boolean;
  setIsStandardDataValid: React.Dispatch<React.SetStateAction<any>>;
  plotsValidationData: any;
  setPlotsValidationData: any;
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  isFertilizerTabActive: boolean;
  setIsFertilizerTabActive: React.Dispatch<React.SetStateAction<boolean>>;
  activeRatesTab: string;
  // activeRatesTab: "biological" | "nutrient" | "seed";
  setActiveRatesTab: React.Dispatch<React.SetStateAction<string>>;

  resetStates: () => void;
  bioApplicationIndex: number;
  setBioApplicationIndex: React.Dispatch<React.SetStateAction<number>>;
}

const NewTrialStateContext = createContext<NewTrialStateContextValue | null>(
  null
);

export const NewTrialStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [trialInfo, setTrialInfo] = useState<any>({
    field_ids: [""],
  });
  const [newTrialState, setNewTrialState] = useState<NewTrialStateInterface>({
    ab_line: [],
    buffer_zone: [],
    machinery_grid: [],
    trial_plots: [],
  });

  const [ratesDosage, setRatesDosage] = useState<RatesDosageInterface>({
    rates_and_dosages: [],
    type: "",
    unit_of_rate: "",
    standard_seed_rate: 0,
    standard_product: "",
    standard_fertilisers: [],
  });
  const [current, setCurrent] = useState<number>(0);
  const [isFertilizerTabActive, setIsFertilizerTabActive] =
    useState<boolean>(false);
  const [activeRatesTab, setActiveRatesTab] = useState<string>("seed");

  const [ratesDosageValidation, setRatesDosageValidation] =
    useState<boolean>(false);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [trialProducts, setTrialProducts] = useState<any>(null);
  const [trialProtocolData, setTrialProtocolData] =
    useState<ProtocolResponse | null>(null);
  const [isStandardDataValid, setIsStandardDataValid] =
    useState<boolean>(false);
  const [plotsValidationData, setPlotsValidationData] =
    useState<PlotsValidationData>({
      polygonsNotInsideField: [],
      intersectingPairs: [],
    });
  const [bioApplicationIndex, setBioApplicationIndex] = useState<number>(0);

  const resetStates = (): void => {
    setTrialInfo({ field_ids: [""] });
    setNewTrialState({
      ab_line: [],
      buffer_zone: [],
      machinery_grid: [],
      trial_plots: [],
    });
    setRatesDosage({
      rates_and_dosages: [],
      type: "",
      unit_of_rate: "",
      standard_seed_rate: 0,
      standard_product: "",
      standard_fertilisers: [],
    });
    setCurrent(0);
    setIsFertilizerTabActive(false);
    setRatesDosageValidation(false);
    setActiveKeys([]);
    setTrialProducts(null);
    setTrialProtocolData(null);
    setIsStandardDataValid(false);
    setPlotsValidationData({
      polygonsNotInsideField: [],
      intersectingPairs: [],
    });
  };

  const memoizedValue = useMemo(
    () => ({
      current,
      isFertilizerTabActive,
      setIsFertilizerTabActive,
      setCurrent,
      newTrialState,
      setNewTrialState,
      trialInfo,
      setTrialInfo,
      ratesDosage,
      setRatesDosage,
      activeKeys,
      setActiveKeys,
      trialProducts, // todo : refactor to use trialProtocolData
      setTrialProducts,
      trialProtocolData,
      setTrialProtocolData,
      ratesDosageValidation,
      setRatesDosageValidation,
      isStandardDataValid,
      setIsStandardDataValid,
      plotsValidationData,
      setPlotsValidationData,
      activeRatesTab,
      setActiveRatesTab,
      resetStates,
      bioApplicationIndex,
      setBioApplicationIndex,
    }),
    [
      newTrialState,
      setNewTrialState,
      trialInfo,
      setTrialInfo,
      ratesDosage,
      setRatesDosage,
      activeKeys,
      setActiveKeys,
      trialProducts,
      setTrialProducts,
      trialProtocolData,
      setTrialProtocolData,
      ratesDosageValidation,
      setRatesDosageValidation,
      isStandardDataValid,
      setIsStandardDataValid,
      plotsValidationData,
      setPlotsValidationData,
      current,
      setCurrent,
      isFertilizerTabActive,
      setIsFertilizerTabActive,
      activeRatesTab,
      setActiveRatesTab,
      resetStates,
      bioApplicationIndex,
      setBioApplicationIndex,
    ]
  );

  return (
    <NewTrialStateContext.Provider value={memoizedValue}>
      {children}
    </NewTrialStateContext.Provider>
  );
};

export const useNewTrialState = (): NewTrialStateContextValue => {
  const contextValue = useContext(NewTrialStateContext);
  if (!contextValue) {
    throw new Error(
      "useNewTrialState must be used within a NewTrialStateProvider"
    );
  }
  return contextValue;
};
