import AxiosInstance from "../../shared/utils/axios";
export const fetchProtocols = async (orgId: string): Promise<any[]> => {
  const response = await AxiosInstance.get(
    `/protocols?org_id=${orgId}&limit=99`
  );
  return response.data;
};
export const deleteProtocol = async (protocolId: string): Promise<void> => {
  await AxiosInstance.delete(`/protocol/${protocolId}`);
};
