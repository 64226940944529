import { type ReactElement } from "react";
import type { ProductCardProps } from "../interfaces/product-interfaces";
import { Flex, Typography } from "antd";
import { DeleteButton } from "../../../../shared/layout/DeleteButton";
import { DensityRangeComponent } from "./DensityRangeComponent";

export const ProductCard = ({
  globalDensityRange,
  globalDensityValues,
  variety,
  manufacturer,
  productKey,
  id,
  onDeleteProduct,
  updateProductDensityRange,
  productDensityRangeMin,
  productDensityRangeMax,
}: ProductCardProps): ReactElement => {
  return (
    <div className="product-card">
      <Flex vertical={false} align={"center"} justify="space-between" gap={10}>
        <div className="seed-product-variety">
          <Typography>
            <strong>{variety}</strong>
          </Typography>
          {/* <Typography>{manufacturer}</Typography> */}
        </div>
        <div className="card-product-range">
          {!globalDensityRange ? (
            <DensityRangeComponent
              globalDensityRange={globalDensityRange}
              productKey={productKey}
              updateProductDensityRange={updateProductDensityRange}
              productDensityRangeMin={productDensityRangeMin}
              productDensityRangeMax={productDensityRangeMax}
              productId={id}
            />
          ) : (
            <div>
              {globalDensityValues?.min ?? productDensityRangeMin} min /{" "}
              {globalDensityValues?.max ?? productDensityRangeMax} max
            </div>
          )}
          <DeleteButton
            dataName={variety}
            onClick={() => {
              onDeleteProduct(productKey);
            }}
            from="product"
          ></DeleteButton>
        </div>
      </Flex>
    </div>
  );
};
