import React, { useEffect, useState, type ReactElement } from "react";
import { Form, Button, Dropdown, Modal } from "antd";
import * as amplitude from "@amplitude/analytics-browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { menuItems } from "./constants";
import CurrentSteps from "../../shared/layout/Steps";
import { getItems, getNextButtonLabel, getCancelButtonLabel } from "./utils";
import arrowBack from "../../assets/images/arrow-back.svg";
import leftIcon from "../../assets/images/left-arr.svg";
import rightGrayIcon from "../../assets/images/right-arr-gray.svg";
import rightWhiteIcon from "../../assets/images/right-arr-white.svg";
import PlotDesignComponent from "./plotDesignComponent";
import TrailInfoComponent from "./TrialInfoComponent/index";
import useFormHandling from "./hooks/useFormHandling";
import SummaryComponent from "./SummaryComponent";
import { useNavigate } from "react-router";
import RatesDosageComponent from "./ratesDosageComponent";
import { useParams } from "react-router-dom";
import AxiosInstance from "../../shared/utils/axios";
import dayjs from "dayjs";
import { useNewTrialState } from "./hooks/NewTrialStateProvider";
import { route } from "../constant";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import TrialMapComponent from "./trialMapComponent";
import TrailModal from "../../shared/layout/Modal";
import "../style.less";
import type { monitorModaldata } from "../../shared/layout/types";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../shared/utils/translationUtils";
import { Loader } from "../../shared/components/Loader";
import useDownloadWorkOrder from "../../shared/custom-hooks/useDownloadWorkOrder";
import { getUserAmplitudeData } from "../../shared/utils/getUserAmplitudeData";
import { useAuth } from "../../lib/auth/AuthContext";
import { useAppState } from "../../lib/appContext/AppContext";

const NewTrial = (): ReactElement => {
  const { t } = useTranslation();
  const [openBackModal, setOpenBackModal] = useState(false);

  const showBackModal = (): void => {
    setOpenBackModal(true);
  };
  const handleOkBackModal = (): void => {
    navigate(`/app/${route.TRIALS.path}`);
  };

  const handleCancelModal = (): void => {
    setOpenBackModal(false);
  };
  const { error, form, current, checkValue, prev, onFinish } =
    useFormHandling();
  const [trialData, setTrialData] = useState<any>(null);
  const items = getItems();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    trialInfo,
    setTrialInfo,
    setTrialProtocolData,
    isFertilizerTabActive,
    resetStates,
  } = useNewTrialState();
  const [isMapFullscreen, setIsMapFullscreen] = useState(false);
  const [open, setOpen] = useState(false);
  const { downloadISOXMLOrder, downloadmonitorModalForm, isLoading } =
    useDownloadWorkOrder();

  const getTrialData = async (): Promise<any> => {
    try {
      const response = await AxiosInstance.get(
        `/trial/${id ?? (trialInfo.id as string)}`
      );
      setTrialData(response.data);
    } catch (error) {
      console.error(formatTranslation(t("common.error.fetch")), error);
    }
  };

  const { user } = useAuth();

  const { stateValue } = useAppState();
  const amplitudeUserData = getUserAmplitudeData(user, stateValue);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (
      ((id ?? trialInfo?.id) && current === 2) ||
      current === 1 ||
      current === 0
    ) {
      amplitude.logEvent("Trial-Design", {
        buttonName: id ? "Edit Trial" : "New Trial",
        amplitudeUserData,
      });
      getTrialData().catch((error) => {
        console.error(formatTranslation(t("common.error.fetch")), error);
      });
    }
  }, [id, current]);

  useEffect(() => {
    if (trialData) {
      form.setFieldsValue({
        name: trialData?.name,
        protocol_id: trialData?.protocol_id,
        assignee_id: trialData?.assignee_id,
        field_ids: trialData?.field_ids,
        rangePicker: [dayjs(trialData?.start_date), dayjs(trialData?.end_date)],
      });
      setTrialInfo(trialData);
    }
  }, [trialData]);

  const handleToggleMapFullscreen = (): any => {
    setIsMapFullscreen((prevState) => !prevState);
  };

  const fetchProtocolData = async (): Promise<void> => {
    if (trialInfo?.protocol_id)
      try {
        const response = await AxiosInstance.get(
          `protocol/${trialInfo?.protocol_id as string}`
        );
        setTrialProtocolData(response.data);
      } catch (error) {
        console.error(error);
      }
  };

  useEffect(() => {
    const fetchDataAndHandleError = async (): Promise<void> => {
      try {
        await fetchProtocolData();
      } catch (error) {
        console.error(formatTranslation(t("common.error.fetch")), error);
      }
    };

    fetchDataAndHandleError().catch((error) => {
      console.error(formatTranslation(t("common.error.fetch")), error);
      return error;
    });
  }, [trialInfo]);

  const newTrialStyle: React.CSSProperties =
    current === 3
      ? {
          height: "calc(100vh - 200px)",
        }
      : {};

  const showModal = (): void => {
    setOpen(true);
  };

  const handleOk = (data: monitorModaldata, fielFormdData: any): void => {
    if (
      data.selectedmonitorBrand === "isoxml" ||
      data.selectedmonitorModel === "isoxml"
    ) {
      downloadISOXMLOrder(fielFormdData, trialInfo?.id).catch((error: any) => {
        console.error(formatTranslation(t("common.error.fetch")), error);
      });
    } else {
      downloadmonitorModalForm(data).catch((error: any) => {
        console.error(formatTranslation(t("common.error.fetch")), error);
      });
    }
    setOpen(false);
  };

  const handleCancel = (): void => {
    setOpen(false);
  };

  const handleMenuClick = (e: any): void => {
    amplitude.logEvent("Trial-Steps", { buttonName: e.key, amplitudeUserData });
    e.key.toLowerCase() === "save" ? onFinish(form) : showModal();
  };

  useEffect(() => {
    return () => {
      resetStates();
    };
  }, []);

  return (
    <div className="newTrail-page">
      {isLoading && <Loader mask message="" />}
      <Modal
        open={openBackModal}
        title={formatTranslation(t("modal.goBackToListHeaderTrail"))}
        onOk={handleOkBackModal}
        onCancel={handleCancelModal}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button onClick={handleCancelModal}>
              {formatTranslation(t("modal.cancel"))}
            </Button>
            <Button
              className="ct-bg-red ct-text-white"
              onClick={handleOkBackModal}
            >
              {formatTranslation(t("modal.leavePage"))}
            </Button>
          </>
        )}
      >
        <p>{formatTranslation(t("modal.goBackToList"))}</p>
        <p>{formatTranslation(t("modal.goBackToListFooter"))}</p>
      </Modal>
      <ToastContainer />
      <div className="newTrail-header">
        <div className="header-main">
          <div className="breadcrumbs" data-testid="breadcrumbs-div">
            <Button
              className="Left-button-icon"
              onClick={() => {
                // navigate(`/app/${route.TRIALS.path}`);
                showBackModal();
              }}
            >
              <img src={arrowBack} alt="left-icon" />
            </Button>
            <p>
              {id
                ? formatTranslation(t("trials.edit"))
                : formatTranslation(t("trials.new"))}
            </p>
          </div>
          <div className="stepper-common">
            <CurrentSteps current={current} items={items} />
          </div>
        </div>
        <div
          className={`newTrail-data ${isMapFullscreen ? "map-open" : ""}`}
          style={newTrialStyle}
        >
          <div
            className={`left-sec ${current === 4 ? "hide-map" : ""} ${
              isMapFullscreen ? "map-open" : ""
            }`}
          >
            <div
              className={`inner-sec ${current === 2 ? "remove-padding" : ""}`}
            >
              <div>
                <Form
                  data-testid="form-basic"
                  name="basic"
                  onFinish={onFinish}
                  form={form}
                  layout="vertical"
                >
                  <div
                    className={current === 3 ? "scroll-sec-3" : "scroll-sec"}
                  >
                    {current === 0 ? <TrailInfoComponent /> : null}
                    {current === 1 ? (
                      <PlotDesignComponent trialData={trialData} />
                    ) : null}
                    {current === 2 ? <RatesDosageComponent /> : null}
                    {current === 3 ? <SummaryComponent /> : null}
                  </div>
                  {current !== 3 && (
                    <div className="bottom-sec-btn">
                      <Button
                        className="cancel-btn"
                        style={{
                          border: "none",
                          boxShadow: "none",
                          paddingLeft: "0px",
                        }}
                        data-testid="cancel-btn"
                        onClick={() => {
                          if (current > 0) {
                            onFinish(form, true);
                            prev();
                          } else {
                            setOpenBackModal(true);
                          }
                        }}
                      >
                        {
                          <img
                            src={leftIcon}
                            style={{ color: "black" }}
                            alt="left-icon"
                          />
                        }
                        {getCancelButtonLabel(current)}
                      </Button>

                      <Button
                        className={`next-btn ${checkValue ? "" : "active"}`}
                        htmlType="submit"
                        data-testid="next-btn"
                      >
                        {getNextButtonLabel(current, t, formatTranslation)}
                        {getNextButtonLabel(current, t, formatTranslation) !==
                          "Finish" && (
                          <img
                            src={checkValue ? rightWhiteIcon : rightGrayIcon}
                            alt="left-icon"
                          />
                        )}
                      </Button>
                    </div>
                  )}
                </Form>
              </div>
            </div>
          </div>
          {current !== 4 && (
            <div className={`right-sec ${isMapFullscreen ? "map-open" : ""}`}>
              <Button className="btn-map" onClick={handleToggleMapFullscreen}>
                {isMapFullscreen ? (
                  <ArrowRightOutlined />
                ) : (
                  <ArrowLeftOutlined />
                )}
              </Button>
              <TrialMapComponent
                current={current}
                isMapFullscreen={isMapFullscreen}
                isFertilizerTabActive={isFertilizerTabActive}
              />
            </div>
          )}
        </div>
        {current === 3 ? (
          <div className="footer-main">
            <div>
              <Button
                icon={<ArrowLeftOutlined />}
                style={{
                  border: "none",
                  boxShadow: "none",
                  paddingLeft: "0px",
                }}
                size="large"
                className="back-button"
                onClick={
                  current > 0
                    ? () => {
                        prev();
                      }
                    : undefined
                }
              >
                {getCancelButtonLabel(current)}
              </Button>
            </div>
            <div className="bottom-sec-btn">
              <Dropdown.Button
                menu={{ items: menuItems, onClick: handleMenuClick }}
                onClick={showModal}
                size="large"
                className="save-btn"
                icon={<DownOutlined />}
              >
                {getNextButtonLabel(current, t, formatTranslation)}
              </Dropdown.Button>
            </div>

            <TrailModal
              open={open}
              handleOk={handleOk}
              handleCancel={handleCancel}
              fieldId={trialInfo?.field_ids[0]}
              trialId={trialInfo?.id}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(NewTrial);
