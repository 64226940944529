import { useEffect, useState, type ReactElement } from "react";
import { Button, Flex, Input, List, Switch, Tooltip } from "antd";
import dragIcon from "../../../../../../../src/assets/images/drag-icon.svg";
import infoIcon from "../../../../../../assets/images/info.svg";
import { DeleteButton } from "../../../../../../shared/layout/DeleteButton";
import {
  DragDropContext,
  Droppable,
  Draggable,
  type DraggableProvided,
  type DraggableStateSnapshot,
} from "@hello-pangea/dnd";
import { formatTranslation } from "../../../../../../shared/utils/translationUtils";
import { t } from "i18next";
import { useCreateProtocolForm } from "../../../..";

const { TextArea } = Input;
interface applicationObjectType {
  description: string;
}

interface ApplicationInterface {
  loading?: boolean;
}

const ApplicationComponent = ({
  loading = false,
}: ApplicationInterface): ReactElement => {
  const [applicationsData, setApplicationsData] = useState<
    applicationObjectType[]
  >([]);
  const [showApplications, setShowApplications] = useState<boolean>(false);
  const [editingDescriptionIndex, setEditingDescriptionIndex] = useState<
    number | null
  >(null);
  const { form, setFormValidationData, formValidationData } =
    useCreateProtocolForm();

  useEffect(() => {
    if (!loading) {
      setInitialData();
    }
  }, [loading]);

  function setInitialData(): void {
    const formValues = form.getFieldsValue(true);
    const applications = formValues?.biologicals?.applications;
    if (applications && applications?.length > 0) {
      setShowApplications(true);
      setApplicationsData([...applications]);
    }
  }

  useEffect(() => {
    updateBioApplicationFormData();
  }, [applicationsData]);

  function updateBioApplicationFormData(): void {
    const formValues = form.getFieldsValue(true);
    form.setFieldsValue({
      biologicals: {
        ...formValues?.biologicals,
        applications: [...applicationsData],
      },
    });
    setFormValidationData({
      ...formValidationData,
      biologicalApplications: [...applicationsData],
    });
  }

  const handleToggleApplications = (checked: boolean): void => {
    setShowApplications(checked);
    if (checked && applicationsData.length === 0) {
      handleAddApplication();
    }
  };

  const handleAddApplication = (): void => {
    const newApplication: applicationObjectType = {
      description: "",
    };
    const updatedApplications = [...applicationsData, newApplication];
    setApplicationsData(updatedApplications);
  };

  const handleApplicationDelete = (indexToDelete: number): void => {
    applicationsData.splice(indexToDelete, 1);
    setApplicationsData([...applicationsData]);
  };

  const handleApplicationDragEnd = (result: any): void => {
    if (!result.destination) return;
    const reorderedApplications = [...applicationsData];
    const [movedItem] = reorderedApplications.splice(result.source.index, 1);
    reorderedApplications.splice(result.destination.index, 0, movedItem);
    // Keep descriptions intact while updating the name order
    setApplicationsData(reorderedApplications);
  };

  const handleDescriptionEdit = (index: number): void => {
    setEditingDescriptionIndex(index);
  };

  const handleDescriptionSave = (index: number, newValue: string): void => {
    // Copy the applications data to avoid state mutation
    const updatedApplications = [...applicationsData];
    // Update the description of the specific application
    updatedApplications[index].description = newValue.trim();
    setApplicationsData(updatedApplications);
    setEditingDescriptionIndex(null);
    setEditingDescriptionIndex(null);
  };

  const renderApplicationItem = (
    item: applicationObjectType,
    index: number,
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot
  ): ReactElement => (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={`application-list-item ${
        snapshot.isDragging ? "application-item-dragging" : ""
      }`}
    >
      <List.Item>
        <div className="application-item-icon">
          <img src={dragIcon} alt="dragIcon" />
        </div>
        <div className="application-midpor-layout">
          <div className="application-item-name">Application {index + 1}</div>
          <div className="application-item-description">
            {editingDescriptionIndex === index ? (
              <TextArea
                autoFocus
                defaultValue={item.description}
                placeholder={formatTranslation(
                  t("createProtocol.application.targetPlaceholder")
                )}
                onBlur={(e) => {
                  handleDescriptionSave(index, e.target.value);
                }}
                rows={2}
              />
            ) : (
              <textarea
                onClick={() => {
                  handleDescriptionEdit(index);
                }}
                defaultValue={item.description}
                placeholder={
                  item.description === ""
                    ? formatTranslation(
                        t("createProtocol.application.targetPlaceholder")
                      )
                    : ""
                }
              />
            )}
          </div>
        </div>
        <div className="application-item-actions">
          <Flex vertical={false} align="center" gap={8}>
            <DeleteButton
              onClick={() => {
                handleApplicationDelete(index);
              }}
            />
          </Flex>
        </div>
      </List.Item>
    </div>
  );
  return (
    <div className="application-component">
      <div className="application-toggle">
        <Switch
          checked={showApplications}
          onChange={handleToggleApplications}
          checkedChildren=""
        />
        <span>Add time of application</span>
        <span>
          <Tooltip
            placement="right"
            title={formatTranslation(
              t("createProtocol.basicInfo.ApplicationTooltip")
            )}
          >
            <img
              className="add-application-info-icon"
              src={infoIcon}
              alt="info icon"
            />
          </Tooltip>
        </span>
      </div>
      {showApplications && (
        <DragDropContext onDragEnd={handleApplicationDragEnd}>
          <Droppable droppableId="applications">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="list-container"
              >
                <List
                  size="large"
                  rowKey={(item) => `${item.description}${Math.random()}`}
                  dataSource={applicationsData}
                  renderItem={(item, index) => (
                    <Draggable
                      draggableId={`draggable-${index}`}
                      index={index}
                      key={`draggable-${index}`}
                    >
                      {(provided, snapshot) =>
                        renderApplicationItem(item, index, provided, snapshot)
                      }
                    </Draggable>
                  )}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {showApplications && (
        <div className="application-footer">
          <Button
            onClick={handleAddApplication}
            className="add-application-btn"
            disabled={!showApplications}
          >
            {formatTranslation(t("createProtocol.application.addButton"))}
          </Button>
        </div>
      )}
    </div>
  );
};
export default ApplicationComponent;
