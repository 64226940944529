import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { route } from "../constant";
import { fetchProtocols, deleteProtocol } from "./protocolsService";
import { ProtocolsHeader } from "./ProtocolsHeader";
import ProtocolTable from "./ProtocolTable";
import { ProtocolsColumns } from "./table-config";
import { Loader } from "../../shared/components/Loader";
import ProtocolDrawer from "../../shared/layout/ProtocolDrawer";
import { formatTranslation } from "../../shared/utils/translationUtils";
import { useAppState } from "../../lib/appContext/AppContext";
import { useTranslation } from "react-i18next";
import "./style.less";

export const Protocols = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>("");
  const { stateValue } = useAppState();
  const [protocolsData, setProtocolsData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedProtocolId, setSelectedProtocolId] = useState<string>("");

  // Fetch Protocols Data
  useEffect((): void => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true);
      try {
        if (stateValue.orgValue?.id) {
          const data = await fetchProtocols(stateValue.orgValue.id);
          setProtocolsData(data);
          setFilteredData(data); // Initialize the table with all protocols
        }
      } catch (error) {
        console.error("Error fetching protocols:", error);
      } finally {
        setIsLoading(false);
      }
    };
    void fetchData();
  }, [stateValue.orgValue]);

  // Search Functionality
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const searchValue = event.target.value;
    setSearchText(searchValue);
    applySearchAndFilter(searchValue, selectedStatuses);
  };

  // Filter Functionality
  const handleFilter = (statuses: string[]): void => {
    setSelectedStatuses(statuses);
    applySearchAndFilter(searchText, statuses);
  };

  // Apply both search and filter
  const applySearchAndFilter = (search: string, statuses: string[]): void => {
    const filtered = protocolsData.filter((protocol) => {
      const matchesSearch = protocol.name
        .toLowerCase()
        .includes(search.toLowerCase());
      const matchesStatus =
        statuses.length === 0 ||
        statuses.includes(protocol.status.toLowerCase());
      return matchesSearch && matchesStatus;
    });
    setFilteredData(filtered);
  };

  // Delete Protocol
  const handleDelete = async (protocolId: string): Promise<void> => {
    try {
      await deleteProtocol(protocolId);
      const updatedData = protocolsData.filter(
        (protocol) => protocol.id !== protocolId
      );
      setProtocolsData(updatedData);
      setFilteredData(updatedData); // Keep table data consistent
    } catch (error) {
      console.error("Error deleting protocol:", error);
    }
  };

  // Open Drawer to View/Edit Protocol
  const handleViewProtocol = (protocolId: string): void => {
    setSelectedProtocolId(protocolId);
    setDrawerVisible(true);
  };

  // Navigate to Create Protocol Page
  const handleCreateProtocol = (): void => {
    navigate(`/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_TRIALTYPE.path}`);
  };

  // Hide Drawer
  const hideDrawer = (): void => {
    setDrawerVisible(false);
  };

  const columns = ProtocolsColumns(handleDelete);

  return (
    <div className="protocols-dashboard-page">
      <ProtocolsHeader
        title={formatTranslation(t("protocols.title"))}
        showCreateButton={protocolsData.length > 0}
        onSearch={handleSearch}
        onFilter={handleFilter}
        onCreate={handleCreateProtocol}
        searchValue={searchText} // Pass the current search value
      />
      <div className="body-content-protocol pt-0">
        {isLoading && <Loader mask message="" />}
        <ProtocolTable
          dataSource={filteredData}
          columns={columns}
          isLoading={isLoading}
          onRowClick={handleViewProtocol}
        />
      </div>
      <ProtocolDrawer
        open={drawerVisible}
        onClose={hideDrawer}
        protocolId={selectedProtocolId}
      />
    </div>
  );
};

export default Protocols;
