import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../../shared/utils/translationUtils";
import PlotsComponent from "../../../../shared/layout/Plots";
import StandardBiologicalRate from "../../../../shared/layout/BiologicalRate";

const CropProtectionComponent: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="rates-main">
      <StandardBiologicalRate />
      <PlotsComponent from={"biologicals"} />
    </div>
  );
};

export default CropProtectionComponent;
