import { SUBMIT_PROTOCOL_LABEL, protocolsSteps } from "./constants";
import { type biologicalsInterface } from "./shared/interfaces/product-interfaces";
import { type ProtocolStepItemType } from "./types";
import { isEmpty } from "lodash";

export const getItems = (): Array<{
  key: string;
  title: string;
  path: string;
  stepNumber: number;
}> => {
  return protocolsSteps.map((item: ProtocolStepItemType) => ({
    key: item.title,
    title: item.title,
    path: item.path,
    stepNumber: item.stepNumber,
  }));
};

export const getCancelButtonLabel = (current: number): string => {
  if (current === 1) return "Cancel";
  return "Back";
};

export const getNextButtonLabel = (current: number): string => {
  if (current === protocolsSteps.length) return SUBMIT_PROTOCOL_LABEL;
  return protocolsSteps[current].title;
};

export const nextStep = (currentPath: string): any => {
  const currentStep = protocolsSteps.find((step) => step.path === currentPath);
  if (currentStep && currentStep.stepNumber !== 5) {
    const nextStepNumber = currentStep.stepNumber + 1;
    const nextStep = protocolsSteps.find(
      (step) => step.stepNumber === nextStepNumber
    );

    return nextStep;
  }
  return undefined;
};

export const prevStep = (currentPath: string): any => {
  const currentStep = protocolsSteps.find((step) => step.path === currentPath);
  if (currentStep && currentStep.stepNumber !== 1) {
    const prevStepNumber = currentStep.stepNumber - 1;
    const prevStep = protocolsSteps.find(
      (step) => step.stepNumber === prevStepNumber
    );
    return prevStep;
  }
  return undefined;
};

export const getCurrentStepNumber = (currentPath: string): number => {
  const currentStep = protocolsSteps.find((step) => step.path === currentPath);
  if (currentStep) return currentStep.stepNumber;
  return 1;
};

export const formatCropsData = (cropsData: any): any[] => {
  const formattedCropsData = cropsData?.map((x: any) => {
    return {
      value: x?.id,
      label: x?.name,
    };
  });
  return formattedCropsData;
};

export const formatDate = (creationDate: string): string => {
  const dateObj = new Date(creationDate);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");

  // Format the date
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const getDefaultActiveTabs = (protocolType: string[]): string => {
  switch (Array.isArray(protocolType)) {
    case protocolType?.includes("seed"):
      return "seed";
    case protocolType?.includes("biological"):
      return "biological";
    case protocolType?.includes("nutrient"):
      return "nutrient";
    default:
      return "seed";
  }
};

export const isBiologicalHasValidData = (
  biologicals: biologicalsInterface
): boolean | undefined => {
  const products = biologicals?.products;
  const applications = biologicals?.applications;
  const tankMixRate = biologicals?.tank_mix_rate;
  const noProductError =
    products && products?.[0]?.variety !== "" && products?.[0]?.rate >= 0;
  const noApplicationError =
    isEmpty(applications) ||
    applications?.every((app) => app?.description?.trim() !== "");
  const noTankMixRateError =
    tankMixRate && tankMixRate?.min >= 0 && tankMixRate?.min < tankMixRate?.max;
  return noProductError && noApplicationError && noTankMixRateError;
};
