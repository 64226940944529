import { useEffect, useState, type FC, type ReactElement } from "react";
import { Button, Collapse, Divider, Flex, Radio, Tabs } from "antd";
import {
  getCircleColor,
  getCircleColorBiologicals,
  getCircleColorNutrient,
} from "../../../../shared/layout/Plots/utils";
import "../../style.less";
import type { NewTrialType, ProtocolResponse, Replicants } from "../../types";
import TabPane from "antd/es/tabs/TabPane";
import ProtcolDrawer from "../../../../shared/layout/ProtocolDrawer";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNewTrialState } from "../../hooks/NewTrialStateProvider";
import "./ratesAndDosage.less";

const RepicantsLabel = ({
  data = [],
}: {
  data: Replicants[];
}): ReactElement => <>{`+ ${data.length} replicants`}</>;

interface SummaryRatesAndDosageProps {
  data: NewTrialType;
  trialProtocolData: ProtocolResponse | null;
}

const RatesAndDosagesGrid: FC<{
  data: NewTrialType;
  trialProtocolData: ProtocolResponse | null;
  type: "seeds" | "fertilisers" | "biologicals";
  handleApplicationChange: (e: any) => void;
  applications?: any;
  bioApplicationIndex: number;
}> = ({
  data,
  trialProtocolData,
  type,
  handleApplicationChange,
  applications,
  bioApplicationIndex,
}) => {
  const ratesAndDosagesObject =
    type === "biologicals" ? "treatments" : "rates_and_dosages";

  return (
    <Flex vertical={true} gap="10px">
      {type === "biologicals" && (
        <div className="section-a">
          <h5 className="summary-text-applications">Applications</h5>
          <Flex vertical gap="middle">
            <Radio.Group
              options={applications}
              defaultValue={1}
              optionType="button"
              buttonStyle="solid"
              onChange={handleApplicationChange}
            />
          </Flex>
        </div>
      )}
      {data.trial_plots?.map((plot, plotIndex: number) => (
        <div className="collapse-one" key={plotIndex}>
          <Collapse expandIconPosition="start" key={plotIndex}>
            <Collapse.Panel
              style={{ padding: "4px 8px" }}
              header={
                <Flex className="plots-col" align="center">
                  <h4>Plot {plotIndex + 1}</h4>
                  {plot?.replicants?.length ? (
                    <RepicantsLabel data={plot.replicants || []} />
                  ) : null}
                </Flex>
              }
              key={plotIndex}
            >
              {/* biologicals item render */}
              {ratesAndDosagesObject === "treatments"
                ? plot?.properties?.[type]?.[ratesAndDosagesObject]?.map(
                    (rateAndDosage: any, rateIndex: number) =>
                      rateIndex !== bioApplicationIndex ? (
                        <></>
                      ) : (
                        rateAndDosage.map(
                          (application: any, booleanIndex: number) => (
                            <div key={rateIndex} className="rates-item">
                              <Flex
                                className="rates-item"
                                vertical={false}
                                gap={10}
                                align="center"
                                style={{ padding: "8px 4px" }}
                              >
                                <Flex className="circle-container">
                                  <div
                                    className="color-circle"
                                    style={{
                                      backgroundColor:
                                        getCircleColorBiologicals(application),
                                    }}
                                  ></div>
                                </Flex>
                                <Flex className="summary-rate-plot-text">
                                  Plot-<span>{booleanIndex + 1}</span>
                                </Flex>

                                <Flex
                                  className="summary-rate-text"
                                  style={{
                                    borderLeft: "1px solid #d9d9d9",
                                    paddingLeft: "8px",
                                  }}
                                >
                                  {
                                    // ? rateAndDosage.map((application:any,appIndex:number) =>
                                    // application.map((application:any) =>
                                    rateIndex === bioApplicationIndex
                                      ? application.treated
                                        ? `Treated-${plot?.properties?.biologicals?.rate} ${plot?.properties?.biologicals?.measure_unit}`
                                        : "Untreated"
                                      : null
                                  }
                                </Flex>
                              </Flex>
                              <Divider
                                className="text-md"
                                style={{ margin: "8px" }}
                              />
                              {/* else seeds/ nutrients item render */}
                            </div>
                          )
                        )
                      )
                  )
                : plot?.properties?.[type]?.[ratesAndDosagesObject]?.map(
                    (rateAndDosage: any, rateIndex: number) => (
                      <div key={rateIndex} className="rates-item">
                        <Flex
                          className="rates-item"
                          vertical={false}
                          gap={10}
                          align="center"
                          style={{ padding: "8px 4px" }}
                        >
                          <Flex className="circle-container">
                            <div
                              className="color-circle"
                              style={{
                                backgroundColor:
                                  type === "seeds"
                                    ? getCircleColor(
                                        "variety" in rateAndDosage
                                          ? rateAndDosage.variety
                                          : "",
                                        rateAndDosage.rate,
                                        trialProtocolData?.product_details
                                          ?.seeds?.products,
                                        null,
                                        plot?.properties?.seeds
                                          ?.rates_and_dosages
                                      )
                                    : getCircleColorNutrient(
                                        plot?.properties?.fertilisers
                                          ?.rates_and_dosages,
                                        rateAndDosage.rate,
                                        "rate",
                                        plotIndex
                                      ),
                              }}
                            ></div>
                          </Flex>
                          <Flex className="summary-rate-plot-text">
                            Plot-<span>{rateIndex + 1}</span>
                          </Flex>

                          <Flex
                            className="summary-rate-text"
                            style={{
                              borderLeft: "1px solid #d9d9d9",
                              paddingLeft: "8px",
                            }}
                          >
                            {"variety" in rateAndDosage
                              ? `${rateAndDosage?.variety as string} (${
                                  rateAndDosage?.rate as number
                                } ${plot?.properties?.seeds?.measure_unit})`
                              : `Amount: ${
                                  rateAndDosage?.amount as number
                                } (kg/ha)`}
                          </Flex>
                        </Flex>
                        <Divider
                          className="text-md"
                          style={{ margin: "8px" }}
                        />
                      </div>
                    )
                  )}
            </Collapse.Panel>
          </Collapse>
        </div>
      ))}
    </Flex>
  );
};

const SummaryRatesAndDosage: FC<SummaryRatesAndDosageProps> = ({
  data,
  trialProtocolData,
}) => {
  const { setIsFertilizerTabActive, setActiveRatesTab } = useNewTrialState();
  type tabType = "seeds" | "fertilisers" | "biologicals";
  const tabs = [];
  const [showDrawer, setShowDrawer] = useState(false);

  const hideDrawer = (): void => {
    setShowDrawer(false);
  };

  const openDrawer = (): void => {
    setShowDrawer(true);
  };

  if (trialProtocolData?.product_details?.seeds) {
    tabs.push({ key: "seeds", label: "Seeds" });
  }

  if (trialProtocolData?.product_details?.nutrients) {
    tabs.push({ key: "fertilisers", label: "Fertilizers" });
  }

  // Temporary hardcoded , linked with nutrients for demo purpose
  if (trialProtocolData?.product_details?.biologicals) {
    tabs.push({ key: "biologicals", label: "Biological" });
  }
  useEffect(() => {
    setIsFertilizerTabActive(
      trialProtocolData?.protocol_type?.[0] === "nutrient"
    );
    setActiveRatesTab(trialProtocolData?.protocol_type?.[0] as string);
    return () => {
      setIsFertilizerTabActive(false);
    };
  }, [trialProtocolData]);

  const { setBioApplicationIndex, bioApplicationIndex: bioIndex } =
    useNewTrialState();
  const bioApplicationIndex = bioIndex ?? 0;
  const handleApplicationChange = (e: any): void => {
    setBioApplicationIndex(e.target.value - 1);
  };

  return (
    <div>
      <ProtcolDrawer
        open={showDrawer}
        onClose={hideDrawer}
        protocolId={trialProtocolData?.id ?? ""}
      />
      <Tabs
        defaultActiveKey="1"
        tabBarExtraContent={
          <Button
            type="text"
            onClick={openDrawer}
            icon={<InfoCircleOutlined />}
          >
            Protocol info
          </Button>
        }
        onChange={(key) => {
          setIsFertilizerTabActive(key === "fertilisers");
          // seeds" | "fertilisers" | "biologicals"
          key === "seeds"
            ? setActiveRatesTab("seed")
            : key === "fertilisers"
            ? setActiveRatesTab("nutrient")
            : setActiveRatesTab("biological");
        }}
      >
        {tabs.map((tab) => {
          // for tabs switch functionality
          const treatmentsArray =
            data.trial_plots[0]?.properties?.biologicals?.treatments ?? [];
          const applications =
            tab.key === "biologicals"
              ? treatmentsArray.map((entry: any, index: number) => {
                  return { label: `${index + 1}`, value: index + 1 };
                })
              : [];
          return (
            <TabPane tab={tab.label} key={tab.key}>
              <RatesAndDosagesGrid
                data={data}
                trialProtocolData={trialProtocolData}
                type={tab.key as "seeds" | "fertilisers" | "biologicals"}
                handleApplicationChange={handleApplicationChange}
                applications={applications}
                bioApplicationIndex={bioApplicationIndex}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default SummaryRatesAndDosage;
