import { useEffect, useState, type ReactElement } from "react";
import AxiosInstance from "../../../shared/utils/axios";
import { Collapse, Divider, Flex } from "antd";
import { EditButton } from "../../../shared/layout/EditButton";

import { useAppState } from "../../../lib/appContext/AppContext";

import { useNewTrialState } from "../hooks/NewTrialStateProvider";
import { useTranslation } from "react-i18next";
import "../../style.less";
import { formatTranslation } from "../../../shared/utils/translationUtils";
import type { NewTrialType } from "../types";
import useFormHandling from "../hooks/useFormHandling";
import SummaryRatesAndDosage from "./ratesAndDosage";
import { usePlotCardState } from "../../../shared/layout/PlotCard/utils";

import "../../../config/styles/theme.less";
const emtpyTrail = {
  ab_line: [],
  buffer_zone: { type: "", size: 0 },
  machinery_grid: { width: 0 },
  trial_plots: [],
  name: "",
  protocol_name: "",
  start_date: "",
  end_date: "",
  standard_field_properties: {
    standard_fertilisers: [],
    standard_product: "",
    standard_seed_rate: 0,
  },
  archived: false,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  protocol_id: "",
  assignee_id: "",
  assignee_name: "",
  field_ids: [],
  org_id: "",
  property_id: "",
  rates_and_dosages: null,
  id: "",
  is_exportable: false,
};

const SummaryComponent = (): ReactElement => {
  // const id = "669965fb55034814ff4a3948";
  const { trialProtocolData, trialInfo } = useNewTrialState();
  const { fieldData, setCurrent } = useFormHandling();
  const { getAbLineColor } = usePlotCardState();

  const id = trialInfo.id;
  const { t } = useTranslation();
  const [summaryData, setSummaryData] = useState<NewTrialType>(emtpyTrail);
  const { stateValue } = useAppState();
  useEffect(() => {
    if (trialInfo) {
      setSummaryData((prevData) => ({
        ...prevData,
        ...trialInfo,
      }));
    }
  }, [trialInfo]);
  const assigneeData = stateValue?.assigneeValue;
  const getTrialData = async (): Promise<any> => {
    try {
      const response = await AxiosInstance.get(`/trial/${id as string}`);
      setSummaryData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      getTrialData().catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [id]);

  const currentAssignee = assigneeData?.find(
    (assigne: any) => assigne.value === trialInfo.assignee_id
  );

  const fieldName =
    fieldData?.length !== 0 &&
    fieldData?.find((field: any) => field.id === trialInfo.field_ids[0])?.name;

  const data: NewTrialType = summaryData;

  return (
    <>
      <div className="summary-main">
        <h3 className="title">
          {formatTranslation(t("newTrial.summary.title"))}
        </h3>
        <p className="description">
          {formatTranslation(t("newTrial.summary.description"))}
        </p>

        <div className="summary-card-main">
          <div className="card-1">
            <Collapse
              ghost
              // icon can also be changed using the property below
              // expandIcon={(props)=><div></div>}
              expandIconPosition="end"
              collapsible="header"
              items={[
                {
                  label: (
                    <Flex justify="space-between">
                      <p className="text-xl text-semibold">
                        {formatTranslation(t("newTrial.summary.title1"))}
                      </p>
                      <EditButton
                        from="trialInfo"
                        onClick={() => {
                          setCurrent(0);
                        }}
                      />
                    </Flex>
                  ),
                  children: (
                    <div>
                      <div className="card-info-sec">
                        <div className="info">
                          <h5>
                            {formatTranslation(t("trials.tableTitle1"))}
                            <Divider className="text-md" type="vertical" />{" "}
                          </h5>
                          <p>{data.name}</p>
                        </div>
                        <div className="info">
                          <h5>
                            {formatTranslation(
                              t("newTrial.trialInfo.protocolSelection")
                            )}
                          </h5>
                          <p>{data.protocol_name}</p>
                        </div>
                        <div className="info">
                          <h5>
                            {formatTranslation(
                              t("newTrial.trialInfo.trialDate")
                            )}
                            <Divider className="text-md" type="vertical" />
                          </h5>
                          <p>
                            {data.start_date} to {data.end_date}
                          </p>
                        </div>
                        <div className="info">
                          <h5>{formatTranslation(t("trials.tableTitle3"))}</h5>
                          <p>{currentAssignee?.label}</p>
                        </div>
                        <div className="info">
                          <h5>
                            {formatTranslation(t("newTrial.summary.fields"))}
                            <Divider className="text-md" type="vertical" />
                          </h5>
                          <p>{fieldName}</p>
                        </div>
                      </div>
                    </div>
                  ),
                },
              ]}
            ></Collapse>
          </div>

          <div className="card-1">
            <Collapse
              ghost
              // icon can also be changed using the property below
              // expandIcon={(props)=><div></div>}
              expandIconPosition="end"
              collapsible="header"
              items={[
                {
                  label: (
                    <Flex justify="space-between">
                      <p className="text-xl text-semibold">
                        {formatTranslation(t("newTrial.summary.title2"))}
                      </p>
                      <EditButton
                        from="plotDesign"
                        onClick={() => {
                          setCurrent(1);
                        }}
                      />
                    </Flex>
                  ),
                  children: (
                  
                    <div className="summary-plot-design">
                      {data.ab_line.length > 0 && (
                        <>
                          <Flex
                            gap={8}
                            vertical={true}
                            className="summary-ab-line"
                          >
                            <p className="text-lg text-semibold text-neutral-60 text-nomargin">
                              {formatTranslation(t("newTrial.summary.abLine"))}
                            </p>
                            {data.ab_line?.map((line: any, index: number) => (
                              <Flex key={index} gap={8} vertical={true}>
                                <Flex
                                  vertical={false}
                                  key={index}
                                  align="center"
                                >
                                  <Flex className="circle-container">
                                    <span
                                      className={`${
                                        getAbLineColor(
                                          line.id,
                                          "className"
                                        ) as string
                                      }  color-circle ab-blue`}
                                      // style={{backgroundColor: getAbLineColor(line.id,"hexCode")}}
                                    ></span>
                                  </Flex>
                                  <p className="text-md text-semibold text-nomargin">
                                    {line.name}
                                  </p>
                                </Flex>
                                <div className="text-md text-nomargin">
                                  <span className="text-semibold text-nomargin">
                                    {formatTranslation(
                                      t("newTrial.summary.machineryGridWidth")
                                    )}
                                  </span>{" "}
                                  <span className="text-sm">
                                    {line.grid_width} m
                                  </span>
                                </div>
                              </Flex>
                            ))}
                          </Flex>

                          {/* class text-md removes default margins from the divider */}
                          <Divider
                            className="text-md"
                            style={{ padding: "0", margin: "0px" }}
                          />
                        </>
                      )}
                      {data.buffer_zone?.size && (
                        <Flex gap={12} vertical={true}>
                          <p className="text-lg text-semibold text-neutral-60 text-nomargin">
                            {formatTranslation(
                              t("newTrial.plotDesign.bufferZone")
                            )}
                          </p>
                          <p className="text-md text-semibold">
                            <span className=" text-semibold">
                              {data.buffer_zone?.type}:{" "}
                            </span>{" "}
                            <span className="text-sm">
                              {data.buffer_zone?.size} m
                            </span>
                          </p>

                          <Divider
                            className="text-md"
                            style={{ padding: "0", margin: "0px" }}
                          />
                        </Flex>
                      )}

                      <Flex vertical={true} gap={12}>
                        <p className="text-lg text-semibold text-neutral-60 text-nomargin">
                          {formatTranslation(
                            t("newTrial.plotDesign.trialPlots")
                          )}
                        </p>
                        {data.trial_plots?.map((plot: any, index: number) => (
                          <p className="text-md" key={index}>
                            <span className=" text-semibold">
                              {" "}
                              {plot.plot.name}{" "}
                            </span>{" "}
                            <span>
                              {plot.plot.rows * plot.plot.columns} plots{" "}
                              {plot.plot.rows} x {plot.plot.columns} |{" "}
                              {plot.plot.row_size}m x {plot.plot.column_size}m{" "}
                            </span>
                          </p>
                        ))}
                      </Flex>
                    </div>
                  ),
                },
              ]}
            ></Collapse>
          </div>

          <div className="card-1">
            <Collapse
              defaultActiveKey={"active"}
              ghost
              key={"active"}
              expandIconPosition="end"
              collapsible="header"
              items={[
                {
                  label: (
                    <Flex justify="space-between">
                      <p className="text-xl text-semibold">
                        {formatTranslation(t("newTrial.summary.title3"))}
                      </p>
                      <EditButton
                        from="ratesAndDosage"
                        onClick={() => {
                          setCurrent(2);
                        }}
                      />
                    </Flex>
                  ),
                  key: "active",
                  children: (
                    <>
                      <SummaryRatesAndDosage
                        data={data}
                        trialProtocolData={trialProtocolData}
                      ></SummaryRatesAndDosage>
                    </>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SummaryComponent;
