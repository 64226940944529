import * as LDClient from "launchdarkly-js-client-sdk";
const ClientSideID = process.env.REACT_APP_LAUCHDARKLY_CLIENT_ID ?? "";

const context: LDClient.LDContext = {
  kind: "user",
};

const client = LDClient.initialize(ClientSideID, context);

client.on("initialized", function () {
  console.log("LaunchDarkly is successfully authenticated to your account.");
});
