/* eslint-disable */
import React, { useEffect, useRef } from "react";
import type mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import MapComponent from "../../../maps/components/MapComponent";
import {
  initializeMap,
  useMapComponent,
} from "../../../maps/components/MapComponent/utils";
import LayersOnMap from "../../../maps/components/MapComponent/LayersOnMap.tsx";
import focusButton from "../../../assets/images/focus-icon.svg";
import serviceButton from "../../../assets/images/service-icon.svg";
import { useNewTrialState } from "../hooks/NewTrialStateProvider";
import { Button } from "antd";
import { ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";
import {
  type IMapComponentRef,
  type TrialMapComponentProps,
} from "../../../maps/interfaces/map.interface";
import useFormHandling from "../hooks/useFormHandling";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";
import { FieldDataInterface } from "../../../maps/components/MapComponent/type";

//const RGBA_SELECTED_FILL = "rgba(49, 180, 242, 0.3)";
const RGBA_SELECTED_FILL = "rgba(242, 246, 248, 0.3)";
const RGBA_UNSELECTED_FILL = "transparent";
//const RGBA_SELECTED_LINE = "rgba(49, 180, 242, 0.3)";
const RGBA_SELECTED_LINE = "white";
const RGBA_UNSELECTED_LINE = "white";
const TEXT_COLOR = "white";

const TrialMapComponent: React.FC<TrialMapComponentProps> = ({
  current,
  isMapFullscreen,
  isFertilizerTabActive,
}) => {
  const { t } = useTranslation();
  const {
    createCustomMarkerElement,
    updateMapData,
    updateABWidth,
    checkPlotBoundaries,
  } = useMapComponent();
  const {
    newTrialState,
    setNewTrialState,
    trialInfo,
    ratesDosage,
    plotsValidationData,
    setPlotsValidationData,
    activeRatesTab,
  } = useNewTrialState();
  const { fieldData, handleFieldSelection } = useFormHandling();
  const mapRef = useRef<IMapComponentRef>({
    getMap: () => null,
    mapContainerRef: null,
  });

  const onLoadMap = (map: mapboxgl.Map): void => {
    map.addSource("geojson-field-data", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer({
      id: "polygon-fill",
      type: "fill",
      source: "geojson-field-data",
      paint: {
        "fill-color": [
          "case",
          ["get", "selected"],
          RGBA_SELECTED_FILL,
          RGBA_UNSELECTED_FILL,
        ],
      },
    });

    map.addLayer({
      id: "polygon-line",
      type: "line",
      source: "geojson-field-data",
      paint: {
        "line-color": [
          "case",
          ["get", "selected"],
          RGBA_SELECTED_LINE,
          RGBA_UNSELECTED_LINE,
        ],
        "line-width": 2,
      },
    });

    map.addLayer({
      id: "polygon-name",
      type: "symbol",
      source: "geojson-field-data",
      layout: {
        "text-field": ["get", "name"],
        "text-size": 16,
        "text-anchor": "center",
      },
      paint: {
        "text-color": TEXT_COLOR,
      },
    });
  };

  useEffect(() => {
    const map = mapRef.current?.getMap();
    map?.on("load", () => {
      initializeMap(map, fieldData, trialInfo, current, handleFieldSelection);
    });
  }, [trialInfo?.field_ids, fieldData, current]);

  useEffect(() => {
    if (fieldData) {
      const map = mapRef.current?.getMap();
      if (map) {
        const geoJsonData = initializeMap(
          map,
          fieldData,
          trialInfo,
          current,
          handleFieldSelection
        );
        if (current === 1 || current === 2 || current === 3) {
          updateMapData(
            map,
            current,
            !!isFertilizerTabActive,
            activeRatesTab,
            fieldData,
            newTrialState,
            setNewTrialState,
            createCustomMarkerElement,
            geoJsonData,
            plotsValidationData,
            setPlotsValidationData
          );
        }
      }
    }
  }, [
    fieldData,
    current,
    isFertilizerTabActive,
    activeRatesTab,
    newTrialState,
    setNewTrialState,
    createCustomMarkerElement,
    trialInfo,
    ratesDosage,
  ]);

  useEffect(() => {
    const abLines = newTrialState.ab_line;
    abLines?.forEach((abItem: any, i: number): void => {
      const map = mapRef.current?.getMap() as mapboxgl.Map;
      const sourceId = `grid-layer-${abItem.id ?? ""}`;
      const polygonGeoJSON = initializeMap(
        map,
        fieldData,
        trialInfo,
        current,
        handleFieldSelection
      );
      const source = map.getSource(sourceId);
      if (source) {
        // @ts-ignore
        const data = source._data;
        const bbox = turf.bbox(data);
        updateABWidth(abItem, sourceId, map, bbox, polygonGeoJSON);
      } else {
        console.error("source not found");
      }
    });
  }, [newTrialState]);

  /* below useEffect triggers plot bounds validation instantly when they are added */
  useEffect(() => {
    const map = mapRef.current?.getMap() as mapboxgl.Map;
    const polygonGeoJSON = initializeMap(
      map,
      fieldData,
      trialInfo,
      current,
      handleFieldSelection
    );

    if (polygonGeoJSON) {
      checkPlotBoundaries(
        polygonGeoJSON,
        plotsValidationData,
        setPlotsValidationData,
        newTrialState
      );
    }
  }, [newTrialState]);

  useEffect(() => {
    const map = mapRef.current?.getMap();
    if (map) {
      map.resize();
    }
  }, [isMapFullscreen]);

  /* 
  below useEffect handles map focus at step1 and step2 in trial flow 
  by adjusting fit bounds of selected field 
  */
  useEffect(() => {
    if (fieldData) {
      const map = mapRef.current?.getMap();

      let coordinates: any[] = [];
      fieldData.forEach((item: FieldDataInterface) => {
        if (item.hasOwnProperty("geometry"))
          coordinates.push(item.geometry.coordinates[0]);
      });
      const fieldsPolygon = turf.polygon(coordinates as any[]);

      if (map && current === 0) {
        const bbox = turf.bbox(fieldsPolygon);
        map.fitBounds(bbox as [number, number, number, number], {
          animate: false,
        });
      } else if (map && current === 1) {
        const selectedField = fieldData.find(
          (field: any) => field.id === trialInfo.field_ids[0]
        );

        const coordinates = selectedField.geometry;
        const bbox = turf.bbox(coordinates);
        map.fitBounds(bbox as [number, number, number, number], {
          animate: false,
        });
      }
    }
  }, [current, fieldData]);

  return (
    <div className="map">
      <MapComponent onLoad={onLoadMap} ref={mapRef}>
        {/* <div className="layer-paragraph">
          <div className="paragraph-content">
            <div className="paragraph-content-container">
              <p>{formatTranslation(t("newTrial.trialMap.layer"))}</p>
              <p>
                {formatTranslation(t("newTrial.trialMap.hide"))}
                <Button className="hide-btn">
                  <MinusOutlined />
                </Button>
              </p>
            </div>
            <div className="layers-main">
              <LayersOnMap />
              <div className="paragraph">
                <p>
                  <ExclamationCircleOutlined />{" "}
                  {formatTranslation(t("newTrial.trialMap.whatShows"))}
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="corner-btn">
          <div className="service-focus-btn">
            <div className="focus-button comman-class">
              <Button>
                <img src={focusButton} alt="service-btn" />
              </Button>
            </div>
            <div className="service-button comman-class">
              <Button>
                <img src={serviceButton} alt="focus-btn" />
              </Button>
            </div>
          </div>
        </div> */}
      </MapComponent>
    </div>
  );
};

export default React.memo(TrialMapComponent);
