import React from "react";
import { ReactComponent as EmptyStateIcon } from "../../../assets/images/IllustrationSmallEmpty.svg";
import "./dropdownEmptyState.less";

export const DropdownEmptyState = (): JSX.Element => (
  <div className="dropdown-empty-state">
    <p>No Results</p>
    <EmptyStateIcon className="icon-empty-state" />
  </div>
);
