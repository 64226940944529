import React from "react";
import { Table, type TableProps } from "antd";
import emptyStateImage from "../../assets/images/Illustration Small Empty.svg";
import { Loader } from "../../shared/components/Loader";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../shared/utils/translationUtils";
import usePagination from "../../shared/custom-hooks/usePagination";
interface ProtocolTableProps extends TableProps<any> {
  dataSource: any[];
  columns: any[];
  isLoading: boolean;
  onRowClick: (id: string) => void;
}

const ProtocolTable: React.FC<ProtocolTableProps> = ({
  dataSource,
  columns,
  isLoading,
  onRowClick,
  ...props
}) => {
  const { t } = useTranslation();
  const { pagination, handleTableChange } = usePagination(
    10,
    dataSource?.length
  );
  const locale = {
    emptyText:
      !isLoading && dataSource.length === 0 ? (
        <div className="new-empty-state-content">
          <img src={emptyStateImage} alt="No Data" />
          <p className="new-empty-state-title">
            {formatTranslation(t("protocols.newEmptyState.title"))}
          </p>
          <p className="new-empty-state-p">
            {formatTranslation(t("protocols.newEmptyState.content"))}
          </p>
        </div>
      ) : (
        <div></div>
      ),
  };
  return (
    <>
      {isLoading && <Loader mask message="" />}
      <Table
        {...props}
        dataSource={dataSource}
        columns={columns}
        locale={locale} // Pass locale with conditional empty state
        pagination={pagination}
        onChange={handleTableChange}
        onRow={(record) => ({
          onClick: () => {
            onRowClick(record.id);
          },
        })}
      />
    </>
  );
};
export default ProtocolTable;
