import { type ReactElement } from "react";
import BasicInfoComponent from "../BasicInfoComponent";
import ObjectivesComponent from "../objectives/ObjectivesComponent";

const RulesComponent = (): ReactElement => {
  return (
    <div className="rules-container">
      <BasicInfoComponent />
      <ObjectivesComponent />
    </div>
  );
};

export default RulesComponent;
