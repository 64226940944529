// | "Initiated"
// | "Draft"
// | "In-Progress"
// | "Published"
// | "Error";

import { createSearchParams, useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import { EditButton } from "../../shared/layout/EditButton";
import { route } from "../constant";
import { DeleteButton } from "../../shared/layout/DeleteButton";
import { Flex, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../shared/utils/translationUtils";
import { formatDate } from "../create-protocol/utils";
import { useAuth } from "../../lib/auth/AuthContext";
import { getUserAmplitudeData } from "../../shared/utils/getUserAmplitudeData";
import { useAppState } from "../../lib/appContext/AppContext";

export const ProtocolsColumns = (handleDeleteProtocol: any): any => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { stateValue } = useAppState();
  const amplitudeUserData = getUserAmplitudeData(user, stateValue);

  const checkTrailCount = (val: string): number => {
    return parseInt(val, 10);
  };

  const PROTOCOLS_COLUMNS = [
    {
      title: formatTranslation(t("createProtocol.basicInfo.protocolName")),
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: string) => <span>{text}</span>,
      width: "20%",
    },
    {
      title: formatTranslation(t("protocol.createdAt")),
      dataIndex: "created_at",
      key: "createdAt",
      sorter: (a: any, b: any) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      render: (creationDate: any) => formatDate(creationDate),
    },
    {
      title: formatTranslation(t("createProtocol.summary.type")),
      key: "protocol_type",
      dataIndex: ["protocol_type"],
      render: (types: string[]) => (
        <span>
          {types.map((type: string): any => {
            let colorType = "";
            let typeName = "";
            if (type === "seed") {
              colorType = "seedType";
              typeName = formatTranslation(t("trialtypes.label.seed"));
            } else if (type === "nutrient") {
              colorType = "nutrientType";
              typeName = formatTranslation(t("trialtypes.label.fertiliser"));
            } else {
              colorType = "cropProtectionType";
              typeName = formatTranslation(
                t("trialtypes.label.cropProtection")
              );
            }
            return (
              <Tag className={colorType} key={type}>
                {typeName}
              </Tag>
            );
          })}
        </span>
      ),

      // else return types.map((type: string) => type).join(" ");
    },
    {
      title: formatTranslation(
        t("createProtocol.objectives.objectiveItemTarget")
      ),
      dataIndex: "objectives",
      render: (objectives: any) => {
        if (objectives === null) return "";
        else
          return objectives
            .map((objective: any) => objective.targets)
            .join(", ");
      },
      key: "target",
    },
    {
      title: formatTranslation(t("createProtocol.basicInfo.protocolCrop")),
      dataIndex: "crop",
      key: "crop",
      sorter: (a: any, b: any) => a.crop.localeCompare(b.crop),
    },
    {
      title: formatTranslation(t("trials")),
      dataIndex: "trial_count",
      key: "trials",
      sorter: (a: any, b: any) =>
        parseInt(a.trial_count) - parseInt(b.trial_count),
    },
    {
      title: formatTranslation(t("trials.status")),
      dataIndex: "status",
      key: "status",
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      render: (status: string) => (
        <span
          className={`status-column ${
            status === "published" ? "protocol-published" : "protocol-draft"
          }`}
        >
          {status.toUpperCase()}
        </span>
      ),
    },
    {
      title: formatTranslation(t("trials.tableTitle7")),
      dataIndex: "actions",
      render: (text: any, record: any) => (
        <Flex
          align="center"
          justify="start"
          gap={5}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <EditButton
            onClick={() => {
              amplitude.logEvent("protocol", {
                buttonName: "edit-protocol",
                amplitudeUserData,
              });
              navigate({
                pathname: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_TRIALTYPE.path}`,
                search: createSearchParams({
                  protocol_id: record.id,
                }).toString(),
              });
            }}
            disabled={checkTrailCount(record.trial_count) > 0 ? true : !true}
          ></EditButton>
          <DeleteButton
            onClick={() => handleDeleteProtocol(record.id)}
            disabled={checkTrailCount(record.trial_count) > 0 ? true : !true}
            dataName={record.name}
          />
        </Flex>
      ),
      fixed: true,
      width: 120,
    },
  ];
  return PROTOCOLS_COLUMNS;
};
