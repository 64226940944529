import { Flex, Select, Table, type TableProps, Radio } from "antd";
import { Option } from "antd/es/mentions";
import { useNewTrialState } from "../../../pages/new-trail/hooks/NewTrialStateProvider";
import {
  type ValidationError,
  type RatesDosageDataInterface,
  type ProductDetails,
  type PlotTableProps,
  // type RatesDosageInterface,
} from "../../../pages/new-trail/types";
import { type FC, useEffect, useState } from "react";
import { InputNumber } from "syngenta-digital-cropwise-react-ui-kit";
// import { useParams } from "react-router-dom";
import populateRatesDosage from "../../../pages/new-trail/hooks/PopulateRatesDosage";
import { get, remove, uniq } from "lodash";
import "./plotTable.less";
import { getCircleColor, getCircleColorNutrient } from "./utils";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";
import VisibilityIcon from "./VisibilityToggle";
import { INPUT_STATUS } from "../../../pages/constant";

interface DataType {
  index: number;
  key: React.Key;
  product: string;
  //   seedrate: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const PlotCellColorBar = (props: any) => {
  const { data, products, ratesDosage } = props;
  const seedsColor = getCircleColor(
    data?.seedrate?.variety,
    data?.seedrate?.rate,
    products,
    null,
    ratesDosage
  );
  return (
    <div
      className="plot-cell-color-bar-container"
      style={{
        background: seedsColor !== "" ? seedsColor : "transparent",
      }}
    >
      {props.children}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const PlotCellColorBarNutrient = (props: any) => {
  const { data, ratesDosage, plotKey } = props;
  const NutrientColor = getCircleColorNutrient(
    ratesDosage,
    data?.fertiliserRate?.fertiliser_rate,
    undefined,
    plotKey
  );
  return (
    <div
      className="plot-cell-color-bar-container"
      style={{
        background: NutrientColor !== "" ? NutrientColor : "transparent",
      }}
    >
      {props.children}
    </div>
  );
};

/* eslint-disable @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention, camelcase */
function validateNutrients(
  data: RatesDosageDataInterface[],
  productDetails: ProductDetails
): ValidationError[] {
  const errors: ValidationError[] = [];
  if (data) {
    if (!productDetails.nutrients) return errors;
    const { application_range, interval_distance, number_of_intervals } =
      productDetails.nutrients;

    // Validate fertiliser_rate within application_range
    data.forEach((item, index) => {
      const rate = item.fertiliser_rate;
      const path = `data[${index}].fertiliser_rate`;
      const messages: string[] = [];

      if (rate !== undefined) {
        if (rate < application_range.min || rate > application_range.max) {
          messages.push(
            `Rate is not in specified range(${String(
              application_range.min
            )} to ${String(application_range.max)}).`
          );
        }
      }

      if (messages.length > 0) {
        errors.push({ path, messages });
      }
    });

    // Create a sorted array of unique rates
    const uniqueRates = Array.from(
      new Set(
        data
          .map((item) => item.fertiliser_rate)
          .filter((rate): rate is number => rate !== undefined && rate != null)
      )
    ).sort((a, b) => a - b);

    // Validate minimum distance between unique rates using the sorted array
    for (let i = 1; i < uniqueRates.length; i++) {
      const prevRate = uniqueRates[i - 1];
      const currRate = uniqueRates[i];
      const path = `data[${data.findIndex(
        (item) => item.fertiliser_rate === currRate
      )}].fertiliser_rate`;
      const messages: string[] = [];

      if (Math.abs(currRate - prevRate) < interval_distance) {
        messages.push(
          `Difference between unique rates (${String(prevRate)} and ${String(
            currRate
          )}) is less than minimum distance (${String(interval_distance)}).`
        );
      }

      if (messages.length > 0) {
        errors.push({ path, messages });
      }
    }

    // Validate distinct number of intervals
    if (uniqueRates.length < number_of_intervals) {
      errors.push({
        path: "global",
        messages: [
          `Number of distinct rates (${String(
            uniqueRates.length
          )}) is less than required intervals (${String(
            number_of_intervals
          )}).`,
        ],
      });
    }
  }
  return errors;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const PlotTable: FC<PlotTableProps> = ({
  ratesAndDoses,
  productDetails,
  flattenedRates,
  vaildateField,
  from,
  plotKey,
}) => {
  const {
    ratesDosage,
    setRatesDosage,
    newTrialState,
    trialProtocolData,
    setRatesDosageValidation,
    bioApplicationIndex,
  } = useNewTrialState();
  const { t } = useTranslation();
  const checkValidation = (): any => {
    if (!trialProtocolData) return;

    let isSeedPlotValid = true;
    let isNutriendPlotValid = true;
    let isBiologicalPlotValid = true;
    if (trialProtocolData.protocol_type?.includes("seeds")) {
      isSeedPlotValid = false;
      isSeedPlotValid = flattenedRates.every(
        (item) =>
          item.variety !== "" &&
          item.rate !== null &&
          item.errors.variety === "" &&
          item.errors.range === ""
      );
    }
    if (trialProtocolData.protocol_type?.includes("nutrient")) {
      isNutriendPlotValid = false;
      //  isNutriendPlotValid = ratesDosage.rates_and_dosages.every((item) => {
      const error = validateNutrients(
        // item,
        ratesDosage.rates_and_dosages.flatMap((plots) => plots),
        trialProtocolData?.product_details
      );
      isNutriendPlotValid = error.length === 0;
      //  return error.length === 0;
      // });
    }
    if (trialProtocolData.protocol_type?.includes("biological")) {
      isBiologicalPlotValid = false;

      const isDataValid = (data: any): boolean => {
        const applications =
          trialProtocolData?.product_details?.biologicals?.applications;
        const num = applications?.length ? applications?.length : 1;
        for (const row of data) {
          for (const entry of row) {
            if (!entry || entry?.bioTreatement?.length !== num) {
              return false;
            }
          }
        }
        return true;
      };

      isBiologicalPlotValid = isDataValid(ratesDosage?.rates_and_dosages); // Example usage
    }
    setRatesDosageValidation(
      isSeedPlotValid && isNutriendPlotValid && isBiologicalPlotValid
    );
  };

  useEffect(() => {
    if (trialProtocolData?.protocol_type?.includes("nutrient")) {
      const error = validateNutrients(
        ratesDosage.rates_and_dosages.flatMap((plots) => plots),
        trialProtocolData?.product_details
      );
      setErrors(error);
    }
  }, [ratesDosage, trialProtocolData]);

  useEffect(() => {
    checkValidation();
  }, [ratesDosage.unit_of_rate, ratesDosage]);

  const [errors, setErrors] = useState<ValidationError[]>([]);
  const getErrorMessages = (path: string) => {
    const error = errors.find((err) => err.path === path);
    return error ? error.messages : [];
  };

  const setSelectedRow = (selectedRowsList: string[]): any => {
    const ratesDosageValue = populateRatesDosage(
      newTrialState.trial_plots,
      selectedRowsList,
      ratesDosage?.rates_and_dosages,
      ratesDosage?.unit_of_rate,
      ratesDosage.standard_seed_rate,
      ratesDosage.standard_product,
      ratesDosage.standard_fertilisers
    );
    setRatesDosage(ratesDosageValue);
  };

  const measureUnit =
    trialProtocolData?.product_details?.biologicals?.products[0]
      ?.measure_unit === "LITERS"
      ? "L/ha"
      : "kg/ha";

  const rowSelection = {
    onSelect: (_record: any, _selected: boolean) => {
      // Find selected rows
      const existing: any = get(
        ratesDosage,
        "rates_and_dosages[0][0].collapseValue",
        []
      );

      const currentRow = _record.key;
      // Toggle current row depending on user action
      _selected
        ? existing.push(currentRow)
        : remove(existing, (row) => row === currentRow);

      // Update selected row
      setSelectedRow(existing);
    },
    onChange: (
      _selectedRowKeys: React.Key[],
      selectedRows: DataType[],
      _info: any
    ) => {
      if (_info.type === "all") {
        // Reset all rows from all tables and toggle all rows from current table
        const selectedRowsList = selectedRows.map((f) => f.key as string);
        setSelectedRow(selectedRowsList);
      }
    },
  };

  const handleUpdateValue = (value: string, plotId: number): void => {
    const updatedRatesDosage = ratesDosage.rates_and_dosages.map(
      (outerItem: any) => {
        return outerItem.map((innerItem: RatesDosageDataInterface) => {
          if (innerItem.plot_id === plotId) {
            return { ...innerItem, variety: value };
          }
          return innerItem;
        });
      }
    );
    setRatesDosage({
      ...ratesDosage,
      rates_and_dosages: updatedRatesDosage,
      type: ratesDosage.type,
      unit_of_rate: ratesDosage.unit_of_rate,
      standard_seed_rate: ratesDosage.standard_seed_rate,
      standard_product: ratesDosage.standard_product,
    });
  };

  const handleInputValue = (values: number | null, data: any): void => {
    vaildateField(data);

    const updatedRatesDosageMap = ratesDosage?.rates_and_dosages?.map(
      (ratesDosagesArray: any) => {
        return ratesDosagesArray.map((ratesDosagesItem: any) => {
          if (ratesDosagesItem?.plot_id === data?.plot_id) {
            return { ...ratesDosagesItem, rate: values };
          }
          return ratesDosagesItem;
        });
      }
    );
    setRatesDosage({
      ...ratesDosage,
      rates_and_dosages: updatedRatesDosageMap,
      type: ratesDosage.type,
      unit_of_rate: ratesDosage.unit_of_rate,
      standard_seed_rate: ratesDosage.standard_seed_rate,
      standard_product: ratesDosage.standard_product,
    });
  };

  const handleFertiliserRateChange = (
    values: number | null,
    data: any
  ): void => {
    // vaildateField(data);
    const updatedRatesDosageMap = ratesDosage?.rates_and_dosages?.map(
      (ratesDosagesArray: any) => {
        return ratesDosagesArray.map((ratesDosagesItem: any) => {
          if (ratesDosagesItem?.plot_id === data?.plot_id) {
            let amount = 0;

            if (values) {
              const total: number =
                ratesDosage?.standard_fertilisers?.reduce((prev, current) => {
                  prev = prev + (current?.rate ?? 0);
                  return prev;
                }, 0) ?? 0;

              amount = Number(((values / 100) * total).toFixed(2));
            }
            return {
              ...ratesDosagesItem,
              fertiliser_rate: values,
              fertiliser_amount: amount,
            };
          }
          return ratesDosagesItem;
        });
      }
    );
    setRatesDosage({
      ...ratesDosage,
      rates_and_dosages: updatedRatesDosageMap,
      type: ratesDosage.type,
      unit_of_rate: ratesDosage.unit_of_rate,
      standard_seed_rate: ratesDosage.standard_seed_rate,
      standard_product: ratesDosage.standard_product,
    });
  };

  const seedColumns: TableProps<DataType>["columns"] = [
    {
      title: formatTranslation(t("trials.plotDesign.trialPlots.table.header1")),
      dataIndex: "index",
      width: 60,
      render: (_, { index }) => {
        return (
          <div className="ml-15">
            {formatTranslation(t("trials.plotDesign.trialPlots.table.cell1"))}{" "}
            {index}
          </div>
        );
      },
    },
    {
      title: formatTranslation(t("trials.plotDesign.trialPlots.table.header2")),
      dataIndex: "product",
      render: (data) => {
        const err = vaildateField(data);
        for (const item of flattenedRates) {
          if (item.plot_id === data.plot_id) {
            item.errors = { ...err };
          }
        }
        return (
          <div>
            <Select
              placeholder={formatTranslation(
                t("trials.plotDesign.trialPlots.select.placeholder")
              )}
              value={data.variety ?? undefined}
              className="plottable-seelct-product select-item"
              onChange={(values) => {
                handleUpdateValue(values, data.plot_id);
              }}
            >
              {productDetails?.products ? (
                productDetails?.products.map((product: any, index: any) => {
                  return (
                    <Option key={index} value={product.variety}>
                      {product.variety}
                    </Option>
                  );
                })
              ) : (
                <></>
              )}
            </Select>
            <div>
              <span className="error-message">{err.range}</span>
              <span className="error-message">{err.variety}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: formatTranslation(t("trials.plotDesign.trialPlots.table.header3")),
      dataIndex: "seedrate",
      width: 100,
      render: (data) => (
        <>
          <InputNumber
            type="number"
            value={data.rate || undefined}
            min={0}
            status={(data?.rate ?? 0) < 0 ? INPUT_STATUS.ERROR : ""}
            onChange={(values) => {
              handleInputValue(values, data);
            }}
            className="input-number"
          />
        </>
      ),
    },
    {
      title: "",
      dataIndex: "",
      width: "20px",
      render: (data: any, record: any, index: number) => {
        return (
          <div>
            <PlotCellColorBar
              data={record}
              products={productDetails?.products}
              ratesDosage={ratesAndDoses}
            >
              <VisibilityIcon
                recordKey={record.key as string}
                isVisible={visibleRows.includes(record.key as string)}
                onToggleVisibility={handleToggleVisibility}
              />
            </PlotCellColorBar>
          </div>
        );
      },
    },
  ];

  const fertilizerColumns: TableProps<DataType>["columns"] = [
    {
      title: formatTranslation(t("trials.plotDesign.trialPlots.table.header1")),
      dataIndex: "index",
      width: 60,
      render: (_, { index }) => {
        return <>{index}</>;
      },
    },
    {
      title: formatTranslation(
        t("trials.plotDesign.trialPlots.table.header.ratePercent")
      ),
      dataIndex: "fertiliserRate",
      render: (data: RatesDosageDataInterface, record: any, index: number) => {
        const startingIndex = ratesDosage.rates_and_dosages
          .slice(0, plotKey)
          .reduce((acc, val) => acc + val.length, 0);
        const errorIndex = startingIndex + index;
        const path = `data[${errorIndex}].fertiliser_rate`;
        const errorMessages = getErrorMessages(path);
        return (
          <Flex vertical flex={1}>
            <InputNumber
              type="number"
              value={data?.fertiliser_rate}
              min={0}
              status={
                (data?.fertiliser_rate ?? 0) < 0 ? INPUT_STATUS.ERROR : ""
              }
              onChange={(value) => {
                handleFertiliserRateChange(value, data);
              }}
              className="input-number"
            />
            {errorMessages.map((message: string, idx: number) => (
              <span key={idx} className="error-message">
                {message}
              </span>
            ))}
          </Flex>
        );
      },
    },
    {
      title: formatTranslation(
        t("trials.plotDesign.trialPlots.table.header.amountKgha")
      ),
      dataIndex: "fertiliserAmount",
      render: (data: RatesDosageDataInterface, dataType: any, index) => {
        return <>{data?.fertiliser_amount ?? "0"}</>;
      },
    },
    {
      title: "",
      dataIndex: "",
      width: "20px",
      render: (data: any, record: any, index: number) => {
        return (
          <div>
            <PlotCellColorBarNutrient
              data={data}
              products={productDetails?.products}
              ratesDosage={ratesAndDoses}
              plotKey={plotKey}
            >
              <VisibilityIcon
                recordKey={record.key as string}
                isVisible={visibleRows.includes(record.key as string)}
                onToggleVisibility={handleToggleVisibility}
              />
              <></>
            </PlotCellColorBarNutrient>
          </div>
        );
      },
    },
  ];

  const bioLogicalColumns: TableProps<DataType>["columns"] = [
    {
      title: formatTranslation(t("trials.plotDesign.trialPlots.table.header1")),
      dataIndex: "index",
      width: 60,
      render: (_, { index }, record: any) => {
        return (
          <PlotCellColorBar
            data={record}
            products={productDetails?.products}
            ratesDosage={ratesAndDoses}
          >
            <span
              style={{
                marginLeft: "10px",
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
              }}
            >
              Plot {index}
            </span>
            <></>
          </PlotCellColorBar>
        );
      },
    },
    {
      title: "Treatement",
      dataIndex: "bioTreatement",
      render: (data: RatesDosageDataInterface, record: any, index: number) => {
        return (
          <Radio.Group
            buttonStyle="solid"
            value={data?.bioTreatement?.[bioApplicationIndex]?.treated}
            onChange={(e) => {
              handleTreatChange(e.target.value, data, record, index);
            }}
          >
            <Radio.Button value={true}>Treated</Radio.Button>
            <Radio.Button value={false}>Untreated</Radio.Button>
          </Radio.Group>
        );
      },
    },
    {
      title: `Applic. Rate ${measureUnit}`,
      dataIndex: "bioTreatement",
      render: (data: RatesDosageDataInterface, dataType: any, indexta) => {
        return (
          <span>
            {data?.bioTreatement?.[bioApplicationIndex]?.treated &&
            productDetails?.products[0] &&
            "rate" in productDetails?.products[0]
              ? `${productDetails?.products[0]?.rate} ${measureUnit as string}`
              : 0}
          </span>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      width: 20,
      render: (data: any, record: any, index: number) => {
        return (
          <VisibilityIcon
            recordKey={record.key as string}
            isVisible={visibleRows.includes(record.key as string)}
            onToggleVisibility={handleToggleVisibility}
          />
        );
      },
    },
  ];

  const columns =
    from === "seeds"
      ? seedColumns
      : from === "biologicals"
      ? bioLogicalColumns
      : fertilizerColumns;

  const handleTreatChange = (
    values: boolean,
    data: any,
    record?: any,
    index?: number
  ): void => {
    const updatedRatesDosageMap = ratesDosage?.rates_and_dosages?.map(
      (ratesDosagesArray: any) => {
        return ratesDosagesArray.map((ratesDosagesItem: any) => {
          if (ratesDosagesItem?.plot_id === data?.plot_id) {
            const treated = {
              treated: values,
            };
            if (ratesDosagesItem?.bioTreatement) {
              ratesDosagesItem.bioTreatement[bioApplicationIndex] = treated;
              return {
                ...ratesDosagesItem,
              };
            }
            return {
              ...ratesDosagesItem,
              bioTreatement: [treated],
            };
          }
          return ratesDosagesItem;
        });
      }
    );
    setRatesDosage({
      ...ratesDosage,
      rates_and_dosages: updatedRatesDosageMap,
      type: ratesDosage.type,
      unit_of_rate: ratesDosage.unit_of_rate,
      standard_seed_rate: ratesDosage.standard_seed_rate,
      standard_product: ratesDosage.standard_product,
    });
  };

  const dataSource: DataType[] = ratesAndDoses?.map((r: any, index: number) => {
    return {
      index: index + 1,
      key: r.plot_id,
      product: r,
      seedrate: r,
      fertiliserRate: r,
      fertiliserAmount: r,
      bioTreatement: r,
    };
  });

  const selectedRowKeys = uniq(
    get(ratesDosage, "rates_and_dosages[0][0].collapseValue", [])
  );

  const [visibleRows, setVisibleRows] = useState<string[]>(selectedRowKeys);
  const handleToggleVisibility = (recordKey: string) => {
    const newVisibleRows = visibleRows.includes(recordKey)
      ? visibleRows.filter((key) => key !== recordKey)
      : [...visibleRows, recordKey];
    setVisibleRows(newVisibleRows);
    setSelectedRow(newVisibleRows);
  };

  return (
    <Table
      className="trialing-plot-table-container"
      pagination={false}
      rowSelection={
        from === "seeds"
          ? {
              type: "checkbox",
              ...rowSelection,
              renderCell: (
                value: any,
                record: DataType,
                index: number,
                originNode: any
              ) => {
                return <></>;
              },
              selectedRowKeys,
            }
          : undefined
      }
      columns={columns}
      dataSource={dataSource}
    />
  );
};
export default PlotTable;
