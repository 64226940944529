import { type FC } from "react";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import "./visibilityIcon.less";

interface VisibilityIconProps {
  recordKey: string;
  isVisible: boolean;
  onToggleVisibility: (recordKey: string) => void;
}

const VisibilityIcon: FC<VisibilityIconProps> = ({
  recordKey,
  isVisible,
  onToggleVisibility,
}) => {
  return (
    <div className="visibility-icon">
      {isVisible ? (
        <EyeOutlined
          className="visible-icon"
          onClick={() => {
            onToggleVisibility(recordKey);
          }}
        />
      ) : (
        <EyeInvisibleOutlined
          className="invisible-icon"
          onClick={() => {
            onToggleVisibility(recordKey);
          }}
        />
      )}
    </div>
  );
};

export default VisibilityIcon;
