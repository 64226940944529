import { type FC } from "react";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import {
  EditOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { type ReplicantComponentProps } from "../../../../pages/new-trail/types";
import { useNewTrialState } from "../../../../pages/new-trail/hooks/NewTrialStateProvider";
import { Tooltip } from "antd";
import { formatTranslation } from "../../../utils/translationUtils";
import { useTranslation } from "react-i18next";

const ReplicantComponent: FC<ReplicantComponentProps> = ({
  valData,
  index,
  toggleVisibility,
  delButton,
}) => {
  const { newTrialState } = useNewTrialState();
  const { t } = useTranslation();

  return (
    <div
      className="open-sec replicant"
      key={`${valData.id as number}-${index}`}
    >
      <h3 className="open-sec-title">
        {valData?.name}
        <span>
          {" "}
          • {valData.rows * valData.columns} plot {valData?.rows} x{" "}
          {valData.columns} | {valData.column_size} m x {valData.row_size}m
        </span>
      </h3>
      <div className="action-sec">
        <div className="eye-edit">
          <Tooltip title={formatTranslation(t("trail.toggleVisibility"))}>
            <Button
              data-testid="eye-icon"
              onClick={() => {
                toggleVisibility(index, valData, "replicant");
              }}
            >
              {valData.visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </Button>
          </Tooltip>
        </div>
        <div className="del-right">{delButton(valData)}</div>
      </div>
    </div>
  );
};

export default ReplicantComponent;
