import { type TabsProps } from "antd";
import SeedsComponent from "./seeds";
import CropProtectionComponent from "./cropProtection";
import FertiliserComponent from "./fertiliser";
import "../style.less";
import { type ProtocolResponse } from "../types";

export const getRatesAndDosageTabs = (
  protocolData: ProtocolResponse | null
): TabsProps["items"] =>
  [
    {
      key: "seed",
      label: <div className="label-style">Seeds</div>,
      children: <SeedsComponent />,
    },
    {
      key: "biological",
      label: "Biological",
      children: <CropProtectionComponent />,
    },
    {
      key: "nutrient",
      label: "Fertiliser",
      children: <FertiliserComponent />,
    },
  ].filter((val) => protocolData?.protocol_type?.includes(val?.key));
