import { useState, useEffect, useCallback } from "react";
import {
  Select,
  Form,
  Tooltip,
  Button,
  Flex,
  Typography,
  ConfigProvider,
} from "antd";
import { Option } from "antd/es/mentions";
import { InputNumber } from "syngenta-digital-cropwise-react-ui-kit";
import infoIcon from "../../../assets/images/info.svg";
import { useNewTrialState } from "../../../pages/new-trail/hooks/NewTrialStateProvider";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";
import "./style.less";
import PlatFormAPIAxiosInstance from "../../utils/platFormAxios";
import { DropdownEmptyState } from "../../../shared/layout/DropdownEmptyState";
import { Loader } from "../../components/Loader";
import { DeleteIcon } from "../DeleteIcon";

const StandardFertilizerRate = () => {
  const { t } = useTranslation();
  const {
    ratesDosage,
    setRatesDosage,
    trialInfo,
    setIsStandardDataValid,
    trialProtocolData,
  } = useNewTrialState();
  const [products, setProducts] = useState([]);

  const getFertilizersData = useCallback(async () => {
    try {
      const cropData = await PlatFormAPIAxiosInstance.get(
        `/catalog/orgs/${trialProtocolData.org_id}/fertilizers?country=${trialProtocolData.crop_details.country}&full=true&include_hidden=true&size=100`
      );
      setProducts(cropData.data.content);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [trialProtocolData]);

  useEffect(() => {
    if (trialProtocolData.id) {
      getFertilizersData().catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [trialProtocolData]);

  useEffect(() => {
    if (
      trialInfo.standard_field_properties &&
      trialInfo.standard_field_properties !== null
    ) {
      const newData = {
        ...ratesDosage,
        standard_seed_rate:
          trialInfo.standard_field_properties.standard_seed_rate,
        standard_product: trialInfo.standard_field_properties.standard_product,
        standard_fertilisers:
          trialInfo.standard_field_properties.standard_fertilisers,
      };
      setRatesDosage(newData);
    }
  }, [trialInfo.standard_field_properties]);

  const handleStandardFertilizerRateChange = (rate, id) => {
    const updatedRatesAndDosage = ratesDosage.standard_fertilisers;
    const itemToUpdate = updatedRatesAndDosage.find((item) => item.id === id);
    if (itemToUpdate) {
      itemToUpdate.rate = rate;

      const updatedRatesDosageMap = ratesDosage?.rates_and_dosages?.map(
        (ratesDosagesArray) => {
          return ratesDosagesArray.map((ratesDosagesItem) => {
            let amount = 0;

            if (ratesDosagesItem.fertiliser_rate) {
              const total =
                updatedRatesAndDosage?.reduce((prev, current) => {
                  prev = prev + (current?.rate ?? 0);
                  return prev;
                }, 0) ?? 0;

              amount = Number(
                ((ratesDosagesItem.fertiliser_rate / 100) * total).toFixed(2)
              );
            }
            return {
              ...ratesDosagesItem,
              fertiliser_amount: amount,
            };
          });
        }
      );

      setRatesDosage({
        ...ratesDosage,
        rates_and_dosages: updatedRatesDosageMap,
        standard_fertilisers: [...updatedRatesAndDosage],
      });
    }
  };

  const handleStandardFertilizerChange = (fertilizer, id) => {
    const updatedRatesAndDosage = ratesDosage.standard_fertilisers;
    const itemToUpdate = updatedRatesAndDosage.find((item) => item.id === id);
    if (itemToUpdate) {
      itemToUpdate.fertiliser = fertilizer;
      setRatesDosage({
        ...ratesDosage,
        standard_fertilisers: [...updatedRatesAndDosage],
      });
    }
  };

  const addFertilizer = () => {
    const fertilizer = {
      rate: 0,
      fertiliser: "",
      unit_of_rate: "kg/ha",
      id: new Date().getTime(),
    };

    const updatedRatesAndDosage = {
      ...ratesDosage,
      standard_fertilisers: [
        ...(ratesDosage?.standard_fertilisers ?? []),
        fertilizer,
      ],
    };

    setRatesDosage(updatedRatesAndDosage);
  };

  useEffect(() => {
    const validateStandardFertilisers = () => {
      const isValid =
        ratesDosage.standard_fertilisers?.length > 0 &&
        ratesDosage.standard_fertilisers.every(
          (item) => item.rate > 0 && item.fertiliser.trim() !== ""
        );
      setIsStandardDataValid(isValid);
    };

    validateStandardFertilisers();
  }, [ratesDosage]);

  const deleteFertilizer = (id) => {
    const updatedRatesAndDosage = ratesDosage.standard_fertilisers;
    const filteredRates = updatedRatesAndDosage.filter(
      (item) => item.id !== id
    );
    setRatesDosage({
      ...ratesDosage,
      standard_fertilisers: [...filteredRates],
    });
  };

  return (
    <div className="standard-seedrate-main">
      <div className="seedrate-header">
        <p className="seedrate-heading">
          {formatTranslation(
            t("trials.plotDesign.trialPlots.table.fieldsStandardRate")
          )}
        </p>
        <Tooltip
          placement="right"
          title={formatTranslation(
            t("trials.plotDesign.StandardFertilizerRate.tooltip")
          )}
        >
          <img src={infoIcon} alt="info-icon" className="seedrate-info-icon" />
        </Tooltip>
      </div>
      {ratesDosage.standard_fertilisers?.map((item) => {
        return (
          <Form className="seedrate-selections" key={item.id}>
            <ConfigProvider renderEmpty={DropdownEmptyState}>
              <Form.Item
                label="Fertiliser"
                className="select-product"
                style={{ marginBottom: "8px" }}
              >
                <Select
                  placeholder={formatTranslation(
                    t("trials.plotDesign.trialPlots.select")
                  )}
                  value={item.fertiliser}
                  onChange={(product) => {
                    handleStandardFertilizerChange(product, item.id);
                  }}
                  className="selected-product"
                >
                  {products.length > 0 ? (
                    products
                      .filter((product) => {
                        return !ratesDosage.standard_fertilisers.some(
                          (obj) => obj.fertiliser === product.name
                        );
                      })
                      .map((product, index) => {
                        return (
                          <Option key={index} value={product.name}>
                            {product.name}
                          </Option>
                        );
                      })
                  ) : (
                    <Loader mask />
                  )}
                </Select>
                {!item.fertiliser && (
                  <span className="error-message">
                    {formatTranslation(
                      t("trials.plotDesign.trialPlots.errorText.fertiliser")
                    )}
                  </span>
                )}
                {!item.rate && item.fertiliser && (
                  <span className="error-message">
                    {formatTranslation(
                      t("trials.plotDesign.trialPlots.errorText.fertiliserRate")
                    )}
                  </span>
                )}
              </Form.Item>
            </ConfigProvider>
            <Form.Item label={"Rate (kg/ha)"} className="input-seedrate">
              <InputNumber
                type="number"
                min={0}
                value={item.rate ?? 0}
                onChange={(seedRate) => {
                  handleStandardFertilizerRateChange(seedRate, item.id);
                }}
                className="input-number"
              />
            </Form.Item>
            <Flex align="end" vertical>
              <Flex flex={"1"}>
                <span></span>
              </Flex>
              <Flex
                flex={"1"}
                align="end"
                vertical
                style={{ paddingTop: "10px" }}
              >
                <DeleteIcon
                  onClick={() => deleteFertilizer(item?.id)}
                ></DeleteIcon>
              </Flex>
            </Flex>
          </Form>
        );
      })}
      <Flex justify="space-between">
        <Button type="text" onClick={addFertilizer}>
          + More fertilisers
        </Button>
        <Typography.Text>
          Total product:
          {ratesDosage.standard_fertilisers?.reduce((prev, current) => {
            prev = prev + (current?.rate ?? 0);
            return prev;
          }, 0)}
          &nbsp;(kg/ha)
        </Typography.Text>
      </Flex>
    </div>
  );
};

export default StandardFertilizerRate;
