import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../../shared/utils/translationUtils";
import { Flex, InputNumber } from "antd";

export const DensityRangeComponent = ({
  globalDensityRange,
  productKey,
  updateProductDensityRange,
  globalDensityValues,
  productDensityRangeMax,
  productDensityRangeMin,
  productId,
}: any): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div>
      {globalDensityRange === true ? (
        <strong>
          <div>
            {formatTranslation(t("createProtocol.products.densityRange"))}
          </div>
        </strong>
      ) : (
        <></>
      )}
      <Flex gap={10} wrap="wrap">
        <div>
          <div> {formatTranslation(t("createProtocol.products.min"))} </div>
          <InputNumber
            placeholder="0"
            min={1}
            max={999999}
            // value={ globalDensityRange === true ? globalDensityValues.min : productDensityRangeMin ?? null}
            defaultValue={
              globalDensityRange === true
                ? globalDensityValues.min
                : productDensityRangeMin ?? null
            }
            className="basicInfo-input-num"
            onChange={(value) => {
              updateProductDensityRange(productId, "min", value);
            }}
          />
        </div>
        <div>
          <div>{formatTranslation(t("createProtocol.products.max"))}</div>
          <InputNumber
            placeholder="0"
            min={1}
            max={999999}
            // value= { globalDensityRange === true ? globalDensityValues.max : productDensityRangeMax?? null}
            defaultValue={
              globalDensityRange === true
                ? globalDensityValues.max
                : productDensityRangeMax ?? null
            }
            className="basicInfo-input-num"
            onChange={(value) => {
              updateProductDensityRange(productId, "max", value);
            }}
          />
        </div>
      </Flex>
    </div>
  );
};
